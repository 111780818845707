import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import {
  POST_GET_LIST, POST_ADD_ONE, POST_GET_ONE, POST_GET_BY_PARTNER, POST_GET_BY_FILTER, POST_EDIT_ONE, POST_DELETE_ONE, POST_COMMENT, DELETE_COMMENT,
  POST_LIKE,
} from "../actions";

import {
  getPostsListSuccess,
  getPostsListError,

  getOnePostSuccess,
  getOnePostError,

  getPostsByPartnerSuccess,
  getPostsByPartnerError,

  getPostsByFilterSuccess,
  getPostsByFilterError,

  addPostSuccess,
  addPostError,

  addLikeSuccess,
  addLikeError,

  addCommentSuccess,
  addCommentError,

  deleteCommentSuccess,
  deleteCommentError,

  editPostSuccess,
  editPostError,

  deleteOnePostSuccess,
  deleteOnePostError,
} from "./actions";
// import { appendFormData } from "../../helpers/Utils";


//GET LIST
const getPostsListRequest = async (payload) => {

  return await axios.get(api.posts + `/list/${payload.offset}/${payload.limit}`, { headers: { Authorization: payload.token } })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getPostsList({ payload }) {
  try {
    const ret = yield call(getPostsListRequest, payload);

    if (ret && ret.status == 200)
      yield put(getPostsListSuccess({ posts: ret.data.posts || [], count: ret.data.count || 0, reset: payload.reset || false }));

    else yield put(getPostsListError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getPostsListError({ error, message: (error) ? error.message : '' }));
  }
}



//GET ONE
const getOnePostsRequest = async (payload) => {
  return await axios.get(api.posts + `/get-one/${payload._id}`, { headers: { Authorization: payload.token } })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getOnePost({ payload }) {
  try {
    const ret = yield call(getOnePostsRequest, payload);

    if (ret && ret.status == 200)
      yield put(getOnePostSuccess({ post: ret.data.post }));

    else yield put(getOnePostError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getOnePostError({ error, message: (error) ? error.message : '' }));
  }
}

//GET POSTS BY PARTNER
const getPostsByPartnerRequest = async (payload) => {
  console.log('call get posts by partner');

  return await axios.get(api.posts + `/partner/${payload._id}/${payload.offset}/${payload.limit}`, { headers: { Authorization: payload.token } })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getPostsByPartner({ payload }) {
  try {
    const ret = yield call(getPostsByPartnerRequest, payload);

    //console.log("getPostsByPartner : ",ret);


    if (ret && ret.status == 200)
      yield put(getPostsByPartnerSuccess({ posts: ret.data.posts }));

    else yield put(getPostsByPartnerError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getPostsByPartnerError({ error, message: (error) ? error.message : '' }));
  }
}
//GET POSTS BY FILTER
const getPostsByFilterRequest = async (payload) => {
  return await axios.post(api.posts + `/filters/${payload.offset}/${payload.limit}`, payload.filter, { headers: { Authorization: payload.token } })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getPostsByFilter({ payload }) {
  try {
    const ret = yield call(getPostsByFilterRequest, payload);


    if (ret && ret.status == 200)
      yield put(getPostsByFilterSuccess({ posts: ret.data.posts ,count:ret.data.count}));

    else yield put(getPostsByFilterError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getPostsByFilterError({ error, message: (error) ? error.message : '' }));
  }
}






//ADD ONE
const addPostRequest = async (payload) => {
  const data = payload.data

  const frm = new FormData();
  frm.append("desc", data.desc)
  frm.append("is_public", data.is_public)
  frm.append("is_for_parent", data.is_for_parent)
  frm.append("is_for_teacher", data.is_for_teacher)
  frm.append("is_for_student", data.is_for_student)
  frm.append("is_private", data.is_private)
  frm.append("partner_type", data.partner_type)
  // frm.append("user", data.user)
  if (data.inst_id) frm.append("inst_id", data.inst_id)
  if (data.partner) frm.append("partner", data.partner)

  if (data.classes) frm.append("classes", JSON.stringify(data.classes))
  if (data.video) frm.append("video", JSON.stringify(data.video))

  if (Array.isArray(data.gallery))
    for (let i = 0; i < data.gallery.length; i++) {
      frm.append("gallery", data.gallery[i])
    }

  // appendFormData(frm, 'data', data)


  return await axios.post(api.posts, frm, {
    headers: {
      Authorization: payload.token, 'Content-Type': 'multipart/form-data',
    }
  })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* addPost({ payload }) {
  try {
    const ret = yield call(addPostRequest, payload);

    if (ret && ret.status == 201) {
      yield put(addPostSuccess({ post: ret.data.post }));
    }
    else yield put(addPostError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(addPostError({ error, message: (error) ? error.message : '' }));
  }
}





//EDIT ONE
const editOnePostRequest = async (payload) => {
  return await axios.patch(`${api.posts}/edit-one/${payload._id}`, payload.data, { headers: { Authorization: payload.token } })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* editOnePost({ payload }) {
  try {
    const ret = yield call(editOnePostRequest, payload);

    if (ret && ret.status == 200) {
      const { history } = payload;

      if (history) history.push('/app/my-institutions/posts')
      yield put(editPostSuccess({ post: ret.data.post }));
    }
    else yield put(editPostError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(editPostError({ error, message: (error) ? error.message : '' }));
  }
}


// DELETE ONE
const deleteOnePostRequest = async (payload) => {
  return await axios.delete(`${api.posts}/delete-one/${payload._id}`, { headers: { Authorization: payload.token } })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteOnePost({ payload }) {
  try {
    const ret = yield call(deleteOnePostRequest, payload);

    if (ret && ret.status == 202) {
      yield put(deleteOnePostSuccess({ index: payload.index }));
    }
    else yield put(deleteOnePostError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOnePostError({ error, message: (error) ? error.message : '' }));
  }
}

//ADD LIKE
const addLikeRequest = async (payload) => {

  return await axios.post(`${api.likes}/${payload._id}`, { partner: payload.partner, inst_id: payload.inst_id }, { headers: { Authorization: payload.token } })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* addLike({ payload }) {
  try {
    const ret = yield call(addLikeRequest, payload);

    if (ret && ret.status == 200) {
      // const { history } = payload;

      // if (history) history.push('/app/my-institutions/posts')

      yield put(addLikeSuccess({ post: ret.data.post }));
    }
    else yield put(addLikeError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(addLikeError({ error, message: (error) ? error.message : '' }));
  }
}

//ADD COMMENT
const addCommentRequest = async (payload) => {

  return await axios.post(`${api.comments}/${payload._id}`, { message: payload.message, partner: payload.partner, institution: payload.inst, partner_type: payload.partner_type }, { headers: { Authorization: payload.token } })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* addComment({ payload }) {
  try {
    const ret = yield call(addCommentRequest, payload);

    if (ret && ret.status == 200) {
      const { history } = payload;

      if (history) history.push('/app/my-institutions/posts')
      yield put(addCommentSuccess({ post: ret.data.post }));
    }
    else yield put(addCommentError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(addCommentError({ error, message: (error) ? error.message : '' }));
  }
}


//DELETE COMMENT
const deleteCommentRequest = async (payload) => {
  return await axios.delete(`${api.comments}/${payload.post_id}/${payload.id}`, { headers: { Authorization: payload.token } })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteComment({ payload }) {
  try {
    const ret = yield call(deleteCommentRequest, payload);

    if (ret && ret.status == 200) {
      const { history } = payload;

      if (history) history.push('/app/my-institutions/posts')
      yield put(deleteCommentSuccess({ post_id: payload.post_id, comment_id: payload.id }));
    }
    else yield put(deleteCommentError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteCommentError({ error, message: (error) ? error.message : '' }));
  }
}

//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(POST_GET_LIST, getPostsList);
}

export function* watchGetOne() {
  yield takeEvery(POST_GET_ONE, getOnePost);
}
export function* watchGetByPartner() {
  yield takeEvery(POST_GET_BY_PARTNER, getPostsByPartner);
}
export function* watchGetByFilter() {
  yield takeEvery(POST_GET_BY_FILTER, getPostsByFilter);
}

export function* watchEditOne() {
  yield takeEvery(POST_EDIT_ONE, editOnePost);
}

export function* watchAddOne() {
  yield takeEvery(POST_ADD_ONE, addPost);
}

export function* watchAddLike() {
  yield takeEvery(POST_LIKE, addLike);
}

export function* watchAddComment() {
  yield takeEvery(POST_COMMENT, addComment);
}

export function* watchDeleteComment() {
  yield takeEvery(DELETE_COMMENT, deleteComment);
}

export function* watchDeleteOne() {
  yield takeEvery(POST_DELETE_ONE, deleteOnePost);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetOne),
    fork(watchGetByPartner),
    fork(watchGetByFilter),
    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchAddLike),
    fork(watchAddComment),
    fork(watchDeleteComment),
    fork(watchDeleteOne)
  ]);
}
