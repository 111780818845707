import {
  GET_USER_CONTACTS_LIST,
	GET_USER_CONTACTS_LIST_SUCCESS,
  GET_USER_CONTACTS_LIST_ERROR,
} from '../actions';
import { NotificationManager } from '../../components/common/react-notifications';



const INIT_STATE = {
  contacts: [],
  contact_error: '',
  contact_loading: false,
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
    case GET_USER_CONTACTS_LIST:
      return { ...state, contact_loading: true, contact_error: '', 
      count: 0
    };
    case GET_USER_CONTACTS_LIST_SUCCESS:
      return { ...state, contact_loading: false, 
        contacts: action.payload.contacts || [],
        count: action.payload.count || 0
      };
		case GET_USER_CONTACTS_LIST_ERROR:
      return { ...state, contact_loading: false, contact_error: action.payload.message };




		default: return { ...state };
	}
}
