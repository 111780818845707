import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import { PARTNER_GET_USER_PARTNER_LIST,
  PARTNER_GET_LIST, PARTNER_ADD_ONE, PARTNER_GET_ONE, PARTNER_EDIT_ONE, PARTNER_DELETE_ONE
} from "../actions";

import {
  getUserPartnersListSuccess,
  getUserPartnersListError,

  getPartnersListSuccess,
  getPartnersListError,

  getOnePartnerSuccess,
  getOnePartnerError,

  addPartnerSuccess,
  addPartnerError,

  editPartnerSuccess,
  editPartnerError,
  deleteOnePartnerSuccess,
  deleteOnePartnerError,
} from "./actions";


  // GET USER PARTNERS
const getUserPartnersListRequest = async (payload) => {
  return await axios.get(api.users+`/${payload.user_id}/partners`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getUserPartnersList({payload}) {
  try {
    const ret = yield call(getUserPartnersListRequest, payload);
    if (ret && ret.status==200)
    yield put(getUserPartnersListSuccess({partners: ret.data.partners || [], count: ret.data.count || 0}));
    
    else{
      if(ret.status==401){
        localStorage.clear();
        window.location.href = "/"
      }
      else
      yield put(getUserPartnersListError({message: (ret.data) ? ret.data.msg : "Network Error"}))
    } 

  } catch (error) {
    yield put(getUserPartnersListError({error, message: (error) ? error.message : ''}));
  }
}



  //GET LIST
const getPartnersListRequest = async (payload) => {
  return await axios.get(api.partners+`/list/${payload.inst_id}/${payload.offset}/${payload.limit}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getPartnersList({payload}) {
  try {
    const ret = yield call(getPartnersListRequest, payload);
    
    if (ret && ret.status==200)
    yield put(getPartnersListSuccess({partners: ret.data.partners || [], count: ret.data.count || 0}));
    
    else yield put(getPartnersListError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getPartnersListError({error, message: (error) ? error.message : ''}));
  }
}



//GET ONE
const getOnePartnersRequest = async (payload) => {
  return await axios.get(api.partners+`/get-one/${payload._id}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getOnePartner({payload}) {  
  try {
    const ret = yield call(getOnePartnersRequest, payload);

    if (ret && ret.status==200)
    yield put(getOnePartnerSuccess({partner: ret.data.partner}));
    
    else yield put(getOnePartnerError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getOnePartnerError({error, message: (error) ? error.message : ''}));
  }
}




  //ADD ONE
const addPartnerRequest = async (payload) => {
  return await axios.post(api.partners, payload.data, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* addPartner({payload}) {  
  try {
    const ret = yield call(addPartnerRequest, payload);
    
    if (ret && ret.status==201){
      const { history } = payload;

      if(history) history.push('/app/my-institutions/partners')
      yield put(addPartnerSuccess({partner: ret.data.partner}));
    }
    else yield put(addPartnerError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(addPartnerError({error, message: (error) ? error.message : ''}));
  }
}





//EDIT ONE
const editOnePartnerRequest = async (payload) => {
  console.log("editOnePartnerRequest : ",payload);
  
  return await axios.patch(`${api.partners}/edit-one/${payload._id}`, payload.data, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* editOnePartner({payload}) {  
  try {
    const ret = yield call(editOnePartnerRequest, payload);
    
    if (ret && ret.status==200){
      const { history } = payload;

      if(history) history.push('/app/my-institutions/partners')
      yield put(editPartnerSuccess({partner: ret.data.partner}));
    }
    else yield put(editPartnerError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(editPartnerError({error, message: (error) ? error.message : ''}));
  }
}


  // DELETE ONE
const deleteOnePartnerRequest = async (payload) => {
  return await axios.delete(`${api.partners}/delete-one/${payload._id}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* deleteOnePartner({payload}) {  
  try {
    const ret = yield call(deleteOnePartnerRequest, payload);
    
    if (ret && ret.status==202){
      const { history, index } = payload;

      if(history) history.push('/app/my-institutions/partners')
      yield put(deleteOnePartnerSuccess({index}));
    }
    else yield put(deleteOnePartnerError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOnePartnerError({error, message: (error) ? error.message : ''}));
  }
}


//////// WATCHERS
export function* watchGetListUser() {
  yield takeEvery(PARTNER_GET_USER_PARTNER_LIST, getUserPartnersList);
}

export function* watchGetList() {
  yield takeEvery(PARTNER_GET_LIST, getPartnersList);
}

export function* watchGetOne() {
  yield takeEvery(PARTNER_GET_ONE, getOnePartner);
}

export function* watchEditOne() {
  yield takeEvery(PARTNER_EDIT_ONE, editOnePartner);
}

export function* watchAddOne() {
  yield takeEvery(PARTNER_ADD_ONE, addPartner);
}

export function* watchDeleteOne() {
  yield takeEvery(PARTNER_DELETE_ONE, deleteOnePartner);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetListUser),
    fork(watchGetList),
    fork(watchGetOne),
    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchDeleteOne)
  ]);
}
