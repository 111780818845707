import {
	TRAINING_GET_LIST,
	TRAINING_GET_LIST_SUCCESS,
  TRAINING_GET_LIST_ERROR,

  TRAINING_GET_PARTNER_NETWORK,

  TRAINING_SUBSCRIBE,
  TRAINING_SUBSCRIBE_SUCCESS,
  TRAINING_SUBSCRIBE_ERROR,

  TRAINING_GET_ONE,
	TRAINING_GET_ONE_SUCCESS,
  TRAINING_GET_ONE_ERROR,

  TRAINING_ADD_ONE,
  TRAINING_ADD_ONE_SUCCESS,
  TRAINING_ADD_ONE_ERROR,

  TRAINING_EDIT_ONE,
  TRAINING_EDIT_ONE_SUCCESS,
  TRAINING_EDIT_ONE_ERROR,

  TRAINING_DELETE_ONE,
  TRAINING_DELETE_ONE_SUCCESS,
  TRAINING_DELETE_ONE_ERROR
} from '../actions';
import { NotificationManager } from '../../components/common/react-notifications';
import  notificationMessages  from '../../lang/locales/notificationMsg'

const INIT_STATE = {
	trainings: [],
  count: 0,
  training: null,
  error: '',
};

export default (state = INIT_STATE, action) => {
  const messages = notificationMessages()
	switch (action.type) {
		case TRAINING_GET_LIST:
      return { ...state, loading: true, error: '', 
      count: 0
    };
    case TRAINING_GET_LIST_SUCCESS:
      return { ...state, loading: false, 
        trainings: action.payload.trainings || [],
        count: action.payload.count || 0
      };
		case TRAINING_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };




    case TRAINING_GET_PARTNER_NETWORK:
      return { ...state, loading: true, error: '', 
      count: 0
    };




      // GET ONE
    case TRAINING_GET_ONE:
      return { ...state, loading: true, error: ''};
    case TRAINING_GET_ONE_SUCCESS:
      return { ...state, loading: false, error: '',
        training: action.payload.training
      };
    case TRAINING_GET_ONE_ERROR:
      return { ...state, loading: false, error: action.payload.message };









      // ADD ONE
    case TRAINING_ADD_ONE:
      return { ...state, loading: true, error: ''};
    case TRAINING_ADD_ONE_SUCCESS:    
      NotificationManager.success(messages["Added"], messages["Success"],3000, null, null, '');
      return { ...state, loading: false, 
        trainings: [action.payload.training, ...state.trainings]
      };
    case TRAINING_ADD_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };





    
      // EDIT ONE
    case TRAINING_EDIT_ONE:
      return { ...state, loading: true, error: ''};
    case TRAINING_EDIT_ONE_SUCCESS:    
      NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');

      const new_trainings = state.trainings
      const training = action.payload.training
      for (let i = 0; i < new_trainings.length; i++) {
        if(new_trainings[i]._id == training._id){
          new_trainings[i] = training; break;
        }
      }

      return { ...state, loading: false, error: '', training, trainings: new_trainings };
    case TRAINING_EDIT_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

    



    // TOGGLE SUBSCRIBE
    case TRAINING_SUBSCRIBE:
      return { ...state, loading: true, error: ''};
    case TRAINING_SUBSCRIBE_SUCCESS:    
      // NotificationManager.success("Subscribe", "Success",3000, null, null, '');
      const obj = {training: action.payload.training}
      if(action.payload.trainings) obj['trainings'] = action.payload.trainings
      return { ...state, loading: false, error: '',
        ...obj
      };
    case TRAINING_SUBSCRIBE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };





      // DELETE ONE
    case TRAINING_DELETE_ONE:
      return { ...state, loading: true, error: ''};
    case TRAINING_DELETE_ONE_SUCCESS:  
      const trainings = state.trainings 
      if(action.payload.index!=null && action.payload.index!=undefined)
      trainings.splice(action.payload.index, 1)

      return { ...state, loading: false, error: '',
        trainings
      };
    case TRAINING_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

    
		default: return { ...state };
	}
}
