import {
	EVENT_GET_LIST,
	EVENT_GET_LIST_SUCCESS,
  EVENT_GET_LIST_ERROR,

  EVENT_GET_PARTNER_NETWORK,

  EVENT_SUBSCRIBE,
  EVENT_SUBSCRIBE_SUCCESS,
  EVENT_SUBSCRIBE_ERROR,

  EVENT_GET_ONE,
	EVENT_GET_ONE_SUCCESS,
  EVENT_GET_ONE_ERROR,

  EVENT_ADD_ONE,
  EVENT_ADD_ONE_SUCCESS,
  EVENT_ADD_ONE_ERROR,

  EVENT_EDIT_ONE,
  EVENT_EDIT_ONE_SUCCESS,
  EVENT_EDIT_ONE_ERROR,

  EVENT_DELETE_ONE,
  EVENT_DELETE_ONE_SUCCESS,
  EVENT_DELETE_ONE_ERROR
} from '../actions';
import { NotificationManager } from '../../components/common/react-notifications';
import  notificationMessages  from '../../lang/locales/notificationMsg'

const INIT_STATE = {
	events: [],
  count: 0,
  event: null,
  error: '',
};

export default (state = INIT_STATE, action) => {
  const messages = notificationMessages()
	switch (action.type) {
		case EVENT_GET_LIST:
      return { ...state, loading: true, error: '', 
      count: 0
    };
    case EVENT_GET_LIST_SUCCESS:
      return { ...state, loading: false, 
        events: action.payload.events || [],
        count: action.payload.count || 0
      };
		case EVENT_GET_LIST_ERROR:
      return { ...state, loading: false, events: [], count: 0, error: action.payload.message };


    case EVENT_GET_PARTNER_NETWORK:
      return { ...state, loading: true, error: '', 
      count: 0
    };




      // GET ONE
    case EVENT_GET_ONE:
      return { ...state, loading: true, error: ''};
    case EVENT_GET_ONE_SUCCESS:
      return { ...state, loading: false, error: '',
        event: action.payload.event
      };
    case EVENT_GET_ONE_ERROR:
      return { ...state, loading: false, error: action.payload.message };









      // ADD ONE
    case EVENT_ADD_ONE:
      return { ...state, loading: true, error: ''};
    case EVENT_ADD_ONE_SUCCESS:    
      NotificationManager.success(messages["Added"], messages["Success"],3000, null, null, '');
      return { ...state, loading: false, 
        events: [action.payload.event, ...state.events]
      };
    case EVENT_ADD_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };



      // TOGGLE SUBSCRIBE
      case EVENT_SUBSCRIBE:
        return { ...state, loading: true, error: ''};
      case EVENT_SUBSCRIBE_SUCCESS:    
        // NotificationManager.success("Subscribe", "Success",3000, null, null, '');
        const obj = {event: action.payload.event}
        if(action.payload.events) obj['events'] = action.payload.events
        return { ...state, loading: false, error: '',
          ...obj
        };
      case EVENT_SUBSCRIBE_ERROR:
        NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
        return { ...state, loading: false, error: action.payload.message };





    
      // EDIT ONE
    case EVENT_EDIT_ONE:
      return { ...state, loading: true, error: ''};
    case EVENT_EDIT_ONE_SUCCESS:    
      NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
      return { ...state, loading: false, error: '',
      event: action.payload.event
      };
    case EVENT_EDIT_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

    




      // DELETE ONE
    case EVENT_DELETE_ONE:
      return { ...state, loading: true, error: ''};
    case EVENT_DELETE_ONE_SUCCESS:  
      const events = state.events 
      if(action.payload.index!=null && action.payload.index!=undefined)
      events.splice(action.payload.index, 1)

      return { ...state, loading: false, error: '',
        events
      };
    case EVENT_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

    
		default: return { ...state };
	}
}
