import {
  JOB_GET_LIST,
	JOB_GET_LIST_SUCCESS,
  JOB_GET_LIST_ERROR,

  JOB_GET_PARTNER_NETWORK,

  JOB_SUBSCRIBE,
  JOB_SUBSCRIBE_SUCCESS,
  JOB_SUBSCRIBE_ERROR,

  JOB_GET_ONE,
	JOB_GET_ONE_SUCCESS,
  JOB_GET_ONE_ERROR,

  JOB_ADD_ONE,
  JOB_ADD_ONE_SUCCESS,
  JOB_ADD_ONE_ERROR,

  JOB_EDIT_ONE,
  JOB_EDIT_ONE_SUCCESS,
  JOB_EDIT_ONE_ERROR,

  JOB_DELETE_ONE,
  JOB_DELETE_ONE_SUCCESS,
  JOB_DELETE_ONE_ERROR
} from '../actions';

  // GET LIST
export const getJobsList = (payload) => ({
  type: JOB_GET_LIST,
  payload
});
export const getJobsListSuccess = (payload) => ({
  type: JOB_GET_LIST_SUCCESS,
  payload
});
export const getJobsListError = (payload) => ({
  type: JOB_GET_LIST_ERROR,
  payload
});







  // GET LIST
export const getOneJob = (payload) => ({
  type: JOB_GET_ONE,
  payload
});
export const getOneJobSuccess = (payload) => ({
  type: JOB_GET_ONE_SUCCESS,
  payload
});
export const getOneJobError = (payload) => ({
  type: JOB_GET_ONE_ERROR,
  payload
});


// GET LIST
export const getJobsListPartner = (payload) => ({
  type: JOB_GET_PARTNER_NETWORK,
  payload
});




// TOGGLE SUBSCRIBE
export const subscribeJob = (payload) => ({
  type: JOB_SUBSCRIBE,
  payload
});
export const subscribeJobSuccess = (payload) => ({
  type: JOB_SUBSCRIBE_SUCCESS,
  payload
});
export const subscribeJobError = (payload) => ({
  type: JOB_SUBSCRIBE_ERROR,
  payload
});





  // ADD ONE
export const addJob = (payload) => ({
  type: JOB_ADD_ONE,
  payload
});
export const addJobSuccess = (payload) => ({
  type: JOB_ADD_ONE_SUCCESS,
  payload
});
export const addJobError = (payload) => ({
  type: JOB_ADD_ONE_ERROR,
  payload
});




  // EDIT ONE
export const editJob = (payload) => ({
  type: JOB_EDIT_ONE,
  payload
});
export const editJobSuccess = (payload) => ({
  type: JOB_EDIT_ONE_SUCCESS,
  payload
});
export const editJobError = (payload) => ({
  type: JOB_EDIT_ONE_ERROR,
  payload
});




  // DELETE ONE
export const deleteOneJob = (payload) => ({
  type: JOB_DELETE_ONE,
  payload
});
export const deleteOneJobSuccess = (payload) => ({
  type: JOB_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOneJobError = (payload) => ({
  type: JOB_DELETE_ONE_ERROR,
  payload
});