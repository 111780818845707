import {
	GROUP_GET_LIST,
	GROUP_GET_LIST_SUCCESS,
  GROUP_GET_LIST_ERROR,

  GROUP_GET_PARTNER_NETWORK,

  GROUP_SUBSCRIBE,
  GROUP_SUBSCRIBE_SUCCESS,
  GROUP_SUBSCRIBE_ERROR,

  GROUP_GET_ONE,
	GROUP_GET_ONE_SUCCESS,
  GROUP_GET_ONE_ERROR,

  GROUP_ADD_ONE,
  GROUP_ADD_ONE_SUCCESS,
  GROUP_ADD_ONE_ERROR,

  GROUP_EDIT_ONE,
  GROUP_EDIT_ONE_SUCCESS,
  GROUP_EDIT_ONE_ERROR,

  GROUP_DELETE_ONE,
  GROUP_DELETE_ONE_SUCCESS,
  GROUP_DELETE_ONE_ERROR
} from '../actions';
import { NotificationManager } from '../../components/common/react-notifications';
import  notificationMessages  from '../../lang/locales/notificationMsg';

const INIT_STATE = {
	groups: [],
  count: 0,
  group: null,
  error: '',
};

export default (state = INIT_STATE, action) => {
  const messages = notificationMessages()
	switch (action.type) {
		case GROUP_GET_LIST:
      return { ...state, loading: true, error: '', 
      count: 0
    };
    case GROUP_GET_LIST_SUCCESS:
      return { ...state, loading: false, 
        groups: action.payload.groups || [],
        count: action.payload.count || 0
      };
		case GROUP_GET_LIST_ERROR:
      return { ...state, loading: false, groups: [], count: 0, error: action.payload.message };


    case GROUP_GET_PARTNER_NETWORK:
      return { ...state, loading: true, error: '', 
      count: 0
    };




      // GET ONE
    case GROUP_GET_ONE:
      return { ...state, loading: true, error: ''};
    case GROUP_GET_ONE_SUCCESS:
      return { ...state, loading: false, error: '',
        group: action.payload.group
      };
    case GROUP_GET_ONE_ERROR:
      return { ...state, loading: false, error: action.payload.message };









      // ADD ONE
    case GROUP_ADD_ONE:
      return { ...state, loading: true, error: ''};
    case GROUP_ADD_ONE_SUCCESS:    
      NotificationManager.success(messages["Added"], messages["Success"],3000, null, null, '');
      return { ...state, loading: false, 
        groups: [action.payload.group, ...state.groups]
      };
    case GROUP_ADD_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };



      // TOGGLE SUBSCRIBE
      case GROUP_SUBSCRIBE:
        return { ...state, loading: true, error: ''};
      case GROUP_SUBSCRIBE_SUCCESS:    
        // NotificationManager.success("Subscribe", "Success",3000, null, null, '');
        const obj = {group: action.payload.group}
        if(action.payload.groups) obj['groups'] = action.payload.groups
        return { ...state, loading: false, error: '',
          ...obj
        };
      case GROUP_SUBSCRIBE_ERROR:
         NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
        return { ...state, loading: false, error: action.payload.message };





    
      // EDIT ONE
    case GROUP_EDIT_ONE:
      return { ...state, loading: true, error: ''};
    case GROUP_EDIT_ONE_SUCCESS:    
    NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
      return { ...state, loading: false, error: '',
      group: action.payload.group
      };
    case GROUP_EDIT_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

    




      // DELETE ONE
    case GROUP_DELETE_ONE:
      return { ...state, loading: true, error: ''};
    case GROUP_DELETE_ONE_SUCCESS:  
      const groups = state.groups 
      if(action.payload.index!=null && action.payload.index!=undefined)
      groups.splice(action.payload.index, 1)

      return { ...state, loading: false, error: '',
        groups
      };
    case GROUP_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

    
		default: return { ...state };
	}
}
