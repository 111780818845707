import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { api } from "../../../constants/defaultValues";
import axios from "axios";

import { CONFIGS_SUBJECT_GET_LIST,CONFIGS_SUBJECT_GET_ONE,CONFIGS_SUBJECT_ADD_ONE,CONFIGS_SUBJECT_EDIT_ONE,CONFIGS_SUBJECT_DELETE_ONE,CONFIGS_SUBJECT_GET_BY_FILTER
} from "../../actions";
import {
    getConfigsSubjectListSuccess,
    getConfigsSubjectListError,

    getOneConfigsSubjectSuccess,
    getOneConfigsSubjectError,

    addConfigsSubjectSuccess,
    addConfigsSubjectError,

    editOneConfigsSubjectSuccess,
    editOneConfigsSubjectError,

    deleteOneConfigsSubjectSuccess,
    deleteOneConfigsSubjectError,

    getSubjectsByFilterSuccess,
    getSubjectsByFilterError
  } from "./actions";


 //GET LIST
const getConfigsSubjectListRequest = async (payload) => {
    return await axios.get(api.configs+`/subjects/list/${payload.offset}/${payload.limit}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* getConfigsSubjectList({payload}) {
    try {
      const ret = yield call(getConfigsSubjectListRequest, payload);
      
      if (ret && ret.status==200)
      yield put(getConfigsSubjectListSuccess({subjects: ret.data.subjects || [], count: ret.data.count || 0}));
      
      else yield put(getConfigsSubjectListError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      yield put(getConfigsSubjectListError({error, message: (error) ? error.message : ''}));
    }
  }

  //GET SUBJECTS BY FILTER
const getSubjectsbyFilterRequest = async (payload) => {
  return await axios.post(api.subjects+`/${payload.inst_id}/filter/${payload.offset}/${payload.limit}`,payload.filter, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getSubjectsByFilter({payload}) {
  try {
      
    const ret = yield call(getSubjectsbyFilterRequest, payload);
   // console.log("paylod",payload);

    if (ret && ret.status==200)
    yield put(getSubjectsByFilterSuccess({subjects: ret.data.subjects , filter:payload.filter}));
      
    else yield put(getSubjectsByFilterError({message: (ret.data) ? ret.data.msg : "Network Error"}))
    

  } catch (error) {
    yield put(getSubjectsByFilterError({error, message: (error) ? error.message : ''}));
  }
}


//GET ONE
const getOneConfigsSubjectRequest = async (payload) => {
    return await axios.get(api.configs+`/subjects/get-one/${payload._id}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
  function* getOneConfigsSubject({payload}) {
    try {
      const ret = yield call(getOneConfigsSubjectRequest, payload);
  
      if (ret && ret.status==200) yield put(getOneConfigsSubjectSuccess({subject: ret.data.subject}));
      else yield put(getOneConfigsSubjectError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(getOneConfigsSubjectError({error, message: (error) ? error.message : ''}));
    }
  }
  

  //ADD ONE
const addConfigsSubjectRequest = async (payload) => {
    return await axios.post(api.configs+`/subjects`, payload.data, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
  function* addConfigsSubject({payload}) {  
    try {
      const ret = yield call(addConfigsSubjectRequest, payload);
      
      if (ret && ret.status==201){
        const { history } = payload;
  
        if(history) history.push('/admin/settings/subjects')
        yield put(addConfigsSubjectSuccess({subject: ret.data.subject}));
      }
      else yield put(addConfigsSubjectError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(addConfigsSubjectError({error, message: (error) ? error.message : ''}));
    }
  }

  //EDIT ONE
const editOneConfigsSubjectRequest = async (payload) => {
    return await axios.patch(`${api.configs}/subjects/edit-one/${payload._id}`, payload.data, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* editOneConfigsSubject({payload}) {  
    try {
      const ret = yield call(editOneConfigsSubjectRequest, payload);
      
      if (ret && ret.status==200){
        const { history } = payload;
  
        if(history) history.push('/admin/settings/subjects')
        yield put(editOneConfigsSubjectSuccess({subject: ret.data.subject}));
      }
      else yield put(editOneConfigsSubjectError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(editOneConfigsSubjectError({error, message: (error) ? error.message : ''}));
    }
  }
  
  // DELETE ONE
const deleteOneConfigsSubjectRequest = async (payload) => {
    return await axios.delete(`${api.configs}/subjects/delete-one/${payload._id}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* deleteOneConfigsSubject({payload}) {  
    try {
      const ret = yield call(deleteOneConfigsSubjectRequest, payload);
      
      if (ret && ret.status==202){
        const { history, index } = payload;
  
        if(history) history.push('/admin/settings/subjects')
        yield put(deleteOneConfigsSubjectSuccess({index}));
      }
      else yield put(deleteOneConfigsSubjectError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(deleteOneConfigsSubjectError({error, message: (error) ? error.message : ''}));
    }
  }


//////// WATCHERS
export function* watchGetList() {
    yield takeEvery(CONFIGS_SUBJECT_GET_LIST, getConfigsSubjectList);
  }
  
  
export function* watchGetOne() {
    yield takeEvery(CONFIGS_SUBJECT_GET_ONE, getOneConfigsSubject);
  }

  export function* watchGetByFilter() {
    yield takeEvery(CONFIGS_SUBJECT_GET_BY_FILTER, getSubjectsByFilter);
  }
  
export function* watchEditOne() {
    yield takeEvery(CONFIGS_SUBJECT_EDIT_ONE, editOneConfigsSubject);
  }
  
export function* watchAddOne() {
    yield takeEvery(CONFIGS_SUBJECT_ADD_ONE, addConfigsSubject);
  }
  
export function* watchDeleteOne() {
    yield takeEvery(CONFIGS_SUBJECT_DELETE_ONE, deleteOneConfigsSubject);
  }
  
  export default function* rootSaga() {
    yield all([
      fork(watchGetList),
      fork(watchGetOne),
      fork(watchGetByFilter),
      fork(watchEditOne),
      fork(watchAddOne),
      fork(watchDeleteOne)
    ]);
  }