import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import { PARTNER_GET_USER_PARTNER_LIST,
  GET_USER_CONTACTS_LIST
} from "../actions";

import {
  getUserContactsListSuccess,
  getUserContactsListError,

} from "./actions";


  // GET USER PARTNERS
const getUserContactsListRequest = async (payload) => {
  return await axios.get(api.users+`/${payload.user_id}/contacts`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getUserContactsList({payload}) {
  try {
    const ret = yield call(getUserContactsListRequest, payload);
    
    if (ret && ret.status==200)
    yield put(getUserContactsListSuccess({contacts: ret.data.contacts || [], count: ret.data.count || 0}));
    
    else yield put(getUserContactsListError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getUserContactsListError({error, message: (error) ? error.message : ''}));
  }
}




//////// WATCHERS
export function* watchGetUserContacts() {
  yield takeEvery(PARTNER_GET_USER_PARTNER_LIST, getUserContactsList);
}



export default function* rootSaga() {
  yield all([
    fork(watchGetUserContacts),
  ]);
}
