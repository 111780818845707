import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import { SESSION_GET_LIST,SESSION_GET_ONE,SESSION_GET_BY_FILTER,SESSION_ADD_ONE,SESSION_EDIT_ONE,SESSION_DELETE_ONE
} from "../actions";

import {
    getSessionsListSuccess,
    getSessionsListError,

    getOneSessionSuccess,
    getOneSessionError,
    
    getSessionByFilterSuccess,
    getSessionByFilterError,

    addSessionSuccess,
    addSessionError,

    editOneSessionSuccess,
    editOneSessionError,


    deleteOneSessionSuccess,
    deleteOneSessionError,
  } from "./actions";

  //GET LIST
const getSessionsListRequest = async (payload) => {
    return await axios.get(api.sessions+`/list/${payload.inst_id}/${payload.offset}/${payload.limit}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* getSessionsList({payload}) {
    try {
      const ret = yield call(getSessionsListRequest, payload);
      
      if (ret && ret.status==200)
      yield put(getSessionsListSuccess({sessions: ret.data.sessions || [], count: ret.data.count || 0}));
      
      else yield put(getSessionsListError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      yield put(getSessionsListError({error, message: (error) ? error.message : ''}));
    }
  }

  
//GET ONE
const getOneSessionRequest = async (payload) => {
    return await axios.get(api.sessions+`/get-one/${payload._id}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* getOneSession({payload}) {  
    try {
      const ret = yield call(getOneSessionRequest, payload);
  
      if (ret && ret.status==200)
      yield put(getOneSessionSuccess({session: ret.data.session}));
      
      else yield put(getOneSessionError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      yield put(getOneSessionError({error, message: (error) ? error.message : ''}));
    }
  }
  


  
//GET SESSION BY FILTER
const getSessionByFilterRequest = async (payload) => {
  return await axios.post(api.sessions+`/filters/${payload.offset}/${payload.limit}`,payload.filter, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getSessionByFilter({payload}) {
  try {
    const ret = yield call(getSessionByFilterRequest, payload);
    

    if (ret && ret.status==200)
    {
      yield put(getSessionByFilterSuccess({sessions: ret.data.sessions ,  count: ret.data.count || 0}));
    }
    
    else yield put(getSessionByFilterError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getSessionByFilterError({error, message: (error) ? error.message : ''}));
  }
}

 //ADD ONE
const addSessionRequest = async (payload) => {
    return await axios.post(api.sessions, payload.data, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* addSession({payload}) {  
    try {
      const ret = yield call(addSessionRequest, payload);
      
      if (ret && ret.status==201){
        const { history } = payload;
  
        if(history) history.push('/app/schooling')
        yield put(addSessionSuccess({session: ret.data.session}));
      }
      else yield put(addSessionError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(addSessionError({error, message: (error) ? error.message : ''}));
    }
  }
  
 //EDIT ONE
 const editOneSessionRequest = async (payload) => {
    return await axios.patch(`${api.sessions}/edit-one/${payload._id}`, payload.data, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* editOneSession({payload}) {  
    try {
      const ret = yield call(editOneSessionRequest, payload);
      
      if (ret && ret.status==200){
        const { history } = payload;
  
        if(history) history.push('/app/schooling')
        yield put(editOneSessionSuccess({session: ret.data.session}));
      }
      else yield put(editOneSessionError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(editOneSessionError({error, message: (error) ? error.message : ''}));
    }
  }
  

 // DELETE ONE
const deleteOneSessionRequest = async (payload) => {
    return await axios.delete(`${api.sessions}/delete-one/${payload._id}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* deleteOneSession({payload}) {  
    try {
      const ret = yield call(deleteOneSessionRequest, payload);
      
      if (ret && ret.status==202){
        const { history, index } = payload;
  
        if(history) history.push('/app/schooling')
        yield put(deleteOneSessionSuccess({index}));
      }
      else yield put(deleteOneSessionError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(deleteOneSessionError({error, message: (error) ? error.message : ''}));
    }
  }



//////// WATCHERS
  export function* watchGetList() {
    yield takeEvery(SESSION_GET_LIST, getSessionsList);
  }
  
  export function* watchGetOne() {
    yield takeEvery(SESSION_GET_ONE, getOneSession);
  }

  export function* watchGetFilter() {
    yield takeEvery(SESSION_GET_BY_FILTER, getSessionByFilter);
  }
  
  export function* watchEditOne() {
    yield takeEvery(SESSION_EDIT_ONE, editOneSession);
  }
  
  export function* watchAddOne() {
    yield takeEvery(SESSION_ADD_ONE, addSession);
  }
  
  
  export function* watchDeleteOne() {
    yield takeEvery(SESSION_DELETE_ONE, deleteOneSession);
  }
  
  export default function* rootSaga() {
    yield all([
      fork(watchGetList),
      fork(watchGetOne),
      fork(watchGetFilter),
      fork(watchEditOne),
      fork(watchAddOne),
      fork(watchDeleteOne)
    ]);
  }
  
