import {
  GET_USER_CONTACTS_LIST,
  GET_USER_CONTACTS_LIST_SUCCESS,
  GET_USER_CONTACTS_LIST_ERROR,
} from '../actions';




// GET USER CONTACTS
export const getUserContacts = (payload) => ({
  type: GET_USER_CONTACTS_LIST,
  payload
});

export const getUserContactsListSuccess = (payload) => ({
  type: GET_USER_CONTACTS_LIST_SUCCESS,
  payload
});

export const getUserContactsListError = (payload) => ({
  type: GET_USER_CONTACTS_LIST_ERROR,
  payload
});

