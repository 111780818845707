import {
  PARTNER_SELECT_ONE,
  POST_PARTNER_SELECT_ONE,
  PARTNER_CHANGE_SECTION,
  
  PARTNER_GET_USER_PARTNER_LIST,
	PARTNER_GET_USER_PARTNER_LIST_SUCCESS,
  PARTNER_GET_USER_PARTNER_LIST_ERROR,

  PARTNER_GET_LIST,
	PARTNER_GET_LIST_SUCCESS,
  PARTNER_GET_LIST_ERROR,

  PARTNER_GET_ONE,
	PARTNER_GET_ONE_SUCCESS,
  PARTNER_GET_ONE_ERROR,

  PARTNER_ADD_ONE,
  PARTNER_ADD_ONE_SUCCESS,
  PARTNER_ADD_ONE_ERROR,

  PARTNER_EDIT_ONE,
  PARTNER_EDIT_ONE_SUCCESS,
  PARTNER_EDIT_ONE_ERROR,

  PARTNER_DELETE_ONE,
  PARTNER_DELETE_ONE_SUCCESS,
  PARTNER_DELETE_ONE_ERROR
} from '../actions';


// GET USER PARTNERS
export const getUserPartnersList = (payload) => ({
  type: PARTNER_GET_USER_PARTNER_LIST,
  payload
});
export const getUserPartnersListSuccess = (payload) => ({
  type: PARTNER_GET_USER_PARTNER_LIST_SUCCESS,
  payload
});
export const getUserPartnersListError = (payload) => ({
  type: PARTNER_GET_USER_PARTNER_LIST_ERROR,
  payload
});




// GET LIST
export const getPartnersList = (payload) => ({
  type: PARTNER_GET_LIST,
  payload
});
export const getPartnersListSuccess = (payload) => ({
  type: PARTNER_GET_LIST_SUCCESS,
  payload
});
export const getPartnersListError = (payload) => ({
  type: PARTNER_GET_LIST_ERROR,
  payload
});







  // GET ONE
export const getOnePartner = (payload) => ({
  type: PARTNER_GET_ONE,
  payload
});
export const getOnePartnerSuccess = (payload) => ({
  type: PARTNER_GET_ONE_SUCCESS,
  payload
});
export const getOnePartnerError = (payload) => ({
  type: PARTNER_GET_ONE_ERROR,
  payload
});



// SELECT PARTNER
export const selectPartner = (payload) => ({
  type: PARTNER_SELECT_ONE,
  payload
});
// SELECT POST PARTNER
export const selectPartnerPost = (payload) => ({
  type: POST_PARTNER_SELECT_ONE,
  payload
});

// CHANGE SECTION
export const changeSection = (payload) => ({
  type: PARTNER_CHANGE_SECTION,
  payload
});








  // ADD ONE
export const addPartner = (payload) => ({
  type: PARTNER_ADD_ONE,
  payload
});
export const addPartnerSuccess = (payload) => ({
  type: PARTNER_ADD_ONE_SUCCESS,
  payload
});
export const addPartnerError = (payload) => ({
  type: PARTNER_ADD_ONE_ERROR,
  payload
});




  // EDIT ONE
export const editPartner = (payload) => ({
  type: PARTNER_EDIT_ONE,
  payload
});
export const editPartnerSuccess = (payload) => ({
  type: PARTNER_EDIT_ONE_SUCCESS,
  payload
});
export const editPartnerError = (payload) => ({
  type: PARTNER_EDIT_ONE_ERROR,
  payload
});




  // DELETE ONE
export const deleteOnePartner = (payload) => ({
  type: PARTNER_DELETE_ONE,
  payload
});
export const deleteOnePartnerSuccess = (payload) => ({
  type: PARTNER_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOnePartnerError = (payload) => ({
  type: PARTNER_DELETE_ONE_ERROR,
  payload
});