import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import { JOB_GET_LIST, JOB_SUBSCRIBE, JOB_GET_PARTNER_NETWORK, JOB_ADD_ONE, JOB_GET_ONE, JOB_EDIT_ONE, JOB_DELETE_ONE
} from "../actions";

import {
  subscribeJobSuccess,
  subscribeJobError,

  getJobsListSuccess,
  getJobsListError,

  getOneJobSuccess,
  getOneJobError,

  addJobSuccess,
  addJobError,

  editJobSuccess,
  editJobError,
  deleteOneJobSuccess,
  deleteOneJobError,
} from "./actions";


  //GET LIST
const getJobsListRequest = async (payload) => {
  return await axios.get(api.jobs+`/list/${payload.inst_id}/${payload.offset}/${payload.limit}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getJobsList({payload}) {
  try {
    const ret = yield call(getJobsListRequest, payload);
    
    if (ret && ret.status==200)
    yield put(getJobsListSuccess({jobs: ret.data.jobs || [], count: ret.data.count || 0}));
    
    else yield put(getJobsListError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getJobsListError({error, message: (error) ? error.message : ''}));
  }
}


//PARTNER NETWORK JOBS
const getJobsListPartnerRequest = async (payload) => {  
  return await axios.get(api.jobs+`/partner-network/${payload.partner_id}/${payload.offset}/${payload.limit}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getJobsListPartner({payload}) {
  try {
    const ret = yield call(getJobsListPartnerRequest, payload);
    
    if (ret && ret.status==200)
    yield put(getJobsListSuccess({jobs: ret.data.jobs || [], count: ret.data.count || 0}));
    
    else yield put(getJobsListError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getJobsListError({error, message: (error) ? error.message : ''}));
  }
}




//GET ONE
const getOneJobsRequest = async (payload) => {
  return await axios.get(api.jobs+`/get-one/${payload._id}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getOneJob({payload}) {  
  try {
    const ret = yield call(getOneJobsRequest, payload);

    if (ret && ret.status==200)
    yield put(getOneJobSuccess({job: ret.data.job}));
    
    else yield put(getOneJobError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getOneJobError({error, message: (error) ? error.message : ''}));
  }
}






//SUBSCRIBE
const subscribeJobRequest = async (payload) => {
  return await axios.post(api.jobs+`/subscribe/${payload.job_id}`,{
    partner: payload.partner
  }, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* subscribeJob({payload}) {  
  try {
    const ret = yield call(subscribeJobRequest, payload);

    if (ret && ret.status==200){
      const jobs = payload.jobs
      const job = ret.data.job
      if(jobs && jobs[payload.index]) jobs[payload.index] = job
      yield put(subscribeJobSuccess({index: payload.index, jobs, job }));
    }
    
    else yield put(subscribeJobError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(subscribeJobError({error, message: (error) ? error.message : ''}));
  }
}






  //ADD ONE
const addJobRequest = async (payload) => {
  return await axios.post(api.jobs, payload.data, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* addJob({payload}) {  
  try {
    const ret = yield call(addJobRequest, payload);
    
    if (ret && ret.status==201){
      // const { history } = payload;

      // if(history) history.push('/app/my-institutions/jobs')
      yield put(addJobSuccess({job: ret.data.job}));
    }
    else yield put(addJobError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(addJobError({error, message: (error) ? error.message : ''}));
  }
}





  //EDIT ONE
const editOneJobRequest = async (payload) => {
  return await axios.patch(`${api.jobs}/edit-one/${payload._id}`, payload.data, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* editOneJob({payload}) {  
  try {
    const ret = yield call(editOneJobRequest, payload);
    
    if (ret && ret.status==200){
      // const { history } = payload;

      // if(history) history.push('/app/my-institutions/jobs')
      yield put(editJobSuccess({job: ret.data.job}));
    }
    else yield put(editJobError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(editJobError({error, message: (error) ? error.message : ''}));
  }
}


  // DELETE ONE
const deleteOneJobRequest = async (payload) => {
  return await axios.delete(`${api.jobs}/delete-one/${payload._id}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* deleteOneJob({payload}) {  
  try {
    const ret = yield call(deleteOneJobRequest, payload);
    
    if (ret && ret.status==202){
      const { history, index } = payload;

      if(history) history.push('/app/my-institutions/jobs')
      yield put(deleteOneJobSuccess({index}));
    }
    else yield put(deleteOneJobError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOneJobError({error, message: (error) ? error.message : ''}));
  }
}


//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(JOB_GET_LIST, getJobsList);
}

export function* watchSubscribe() {
  yield takeEvery(JOB_SUBSCRIBE, subscribeJob);
}

export function* watchGetListPartner() {
  yield takeEvery(JOB_GET_PARTNER_NETWORK, getJobsListPartner);
}

export function* watchGetOne() {
  yield takeEvery(JOB_GET_ONE, getOneJob);
}

export function* watchEditOne() {
  yield takeEvery(JOB_EDIT_ONE, editOneJob);
}

export function* watchAddOne() {
  yield takeEvery(JOB_ADD_ONE, addJob);
}

export function* watchDeleteOne() {
  yield takeEvery(JOB_DELETE_ONE, deleteOneJob);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetOne),
    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchDeleteOne),
    fork(watchGetListPartner),
    fork(watchSubscribe),
  ]);
}
