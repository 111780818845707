import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import { INSTITUTION_GET_LIST, INSTITUTION_GET_MY_LIST,
  INSTITUTION_ADD_ITEM, INSTITUTION_GET_ITEM, INSTITUTION_ACTIVATE_ITEM, INSTITUTION_DELETE_ONE , INSTITUTION_EDIT_ONE
} from "../actions";

import {
  getInstitutionListSuccess,
  getInstitutionListError,

  getMyInstitutionsSuccess,
  getMyInstitutionsError,

  getInstitutionSuccess,
  getInstitutionError,

  activateInstituteSuccess,
  activateInstituteError,

  addInstitutionSuccess,
  addInstitutionError,

  deleteOneInstitutionSuccess,
  deleteOneInstitutionError ,

  editInstitutionSuccess,
  editInstitutionError
} from "./actions";


//GET LIST
const getInstitutionListRequest = async (payload) => {
  return await axios.get(api.institutions+`/list/${payload.offset}/${payload.limit}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getInstitutionListItems({payload}) {
  try {
    const ret = yield call(getInstitutionListRequest, payload);
    
    if (ret && ret.status==200)
    yield put(getInstitutionListSuccess({institutions: ret.data.institutions || [], count: ret.data.count || 0}));
    
    else yield put(getInstitutionListError((ret.data) ? ret.data.msg : "Network Error"));

  } catch (error) {
    yield put(getInstitutionListError(error));
  }
}









//  GET MY INSTITUTIONS ( institutions that i can manage )
const getInstitutionMyListRequest = async (payload) => {
  return await axios.get(api.institutions+`/me`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getInstitutionMyList({payload}) {
  try {
    const ret = yield call(getInstitutionMyListRequest, payload);
    
    if (ret && ret.status==200)
    yield put(getMyInstitutionsSuccess({institutions: ret.data.institutions || []}));
    
    else yield put(getMyInstitutionsError({message: (ret.data) ? ret.data.msg : "Network Error"}));

  } catch (error) {
    yield put(getMyInstitutionsError({error, message: (error) ? error.message : ''}));
  }
}









//ADD ONE
const addInstitutionRequest = async (payload) => {
  const data = payload.data;
  if(payload.user_id) data['user_id'] = payload.user_id;

  return await axios.post(api.institutions, data, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* addInstitution({ payload }) {
  try {
    const ret = yield call(addInstitutionRequest, payload);
  
    if (ret && ret.status==201)
    yield put(addInstitutionSuccess({institutions: payload.institutions, institution: ret.data.institution}));

    else yield put(addInstitutionError({
      error: {message: (ret.data) ? ret.data.msg : "Network Error" }
    }));
  } catch (error) {
    yield put(addInstitutionError({error}));
  }
}





//GET ONE
const getInstitutionItemRequest = async (payload) => {
  return await axios.get(api.institutions+`/get-one/${payload._id}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getInstitutionItem({payload}) {
  try {
    const ret = yield call(getInstitutionItemRequest, payload);
    
    if (ret && ret.status==200)
    yield put(getInstitutionSuccess({institution: ret.data.institution}));
    
    else yield put(getInstitutionError({msg: (ret.data) ? ret.data.msg : "Network Error"}));

  } catch (error) {
    yield put(getInstitutionError({error}));
  }
}


//EDIT ONE

const editInstitutionItemRequest = async (payload) => {
  return await axios.patch(api.institutions+`/edit-one/${payload._id}`,payload.data, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};

function* editInstitution({payload}) {
  try {
    const ret = yield call(editInstitutionItemRequest, payload);
    
    if (ret && ret.status==200){
      const { history } = payload;

      if(history) history.push('/app')
      yield put(editInstitutionSuccess({institution: ret.data.institution}));
    }
    
    
    else yield put(editInstitutionError({msg: (ret.data) ? ret.data.msg : "Network Error"}));

  } catch (error) {
    yield put(editInstitutionError({error}));
  }
}







//ACTIVATE ONE
const activateInstitutionItemRequest = async (payload) => {
  return await axios.post(api.institutions+`/${payload._id}/active`, {active: payload.active}, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* activateInstitutionItem({payload}) {
  try {
    const ret = yield call(activateInstitutionItemRequest, payload);
    
    if (ret && ret.status==204)
    yield put(activateInstituteSuccess({active: payload.active}));
    
    else yield put(activateInstituteError({msg: (ret.data) ? ret.data.msg : "Network Error"}));

  } catch (error) {
    yield put(activateInstituteError({error}));
  }
}



// DELETE ONE
const deleteOneInstitutionRequest = async (payload) => {
  return await axios.delete(`${api.institutions}/delete-one/${payload._id}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* deleteOneInstitution({payload}) {  
  try {
    const ret = yield call(deleteOneInstitutionRequest, payload);

    console.log('RET', ret);
    
    
    if (ret && ret.status==202){
      const { history, index } = payload;

      if(history) history.push('/app/my-institutions/institutions')
      yield put(deleteOneInstitutionSuccess({index}));
    }
    else yield put(deleteOneInstitutionError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOneInstitutionError({error, message: (error) ? error.message : ''}));
  }
}
  





//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(INSTITUTION_GET_LIST, getInstitutionListItems);
}

export function* watchGetMyList() {
  yield takeEvery(INSTITUTION_GET_MY_LIST, getInstitutionMyList);
}

export function* watchGetItem() {
  yield takeEvery(INSTITUTION_GET_ITEM, getInstitutionItem);
}

export function* watchActivateItem() {
  yield takeEvery(INSTITUTION_ACTIVATE_ITEM, activateInstitutionItem);
}

export function* wathcAddItem() {
  yield takeEvery(INSTITUTION_ADD_ITEM, addInstitution);
}
export function* wathcEditItem() {
  yield takeEvery(INSTITUTION_EDIT_ONE, editInstitution);
}

export function* watchDeleteOne() {
  yield takeEvery(INSTITUTION_DELETE_ONE, deleteOneInstitution);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList), 
    fork(watchGetMyList),
    fork(watchGetItem),
    fork(wathcAddItem),
    fork(watchActivateItem),
    fork(watchDeleteOne),
    fork(wathcEditItem),
  ]);
}
