import {
    SMS_HISTORY_GET,
    SMS_HISTORY_GET_SUCCESS,
    SMS_HISTORY_GET_ERROR,

    ACCOUNTS_GET_LIST,
    ACCOUNTS_GET_LIST_SUCCESS,
    ACCOUNTS_GET_LIST_ERROR,

    CHECK_ACCOUNT,
    CHECK_ACCOUNT_SUCCESS,
    CHECK_ACCOUNT_ERROR,

    ACCOUNT_GET_ONE,
    ACCOUNT_GET_ONE_SUCCESS,
    ACCOUNT_GET_ONE_ERROR,

    ACCOUNT_ADD_ONE,
    ACCOUNT_ADD_ONE_SUCCESS,
    ACCOUNT_ADD_ONE_ERROR,

    ACCOUNT_EDIT_ONE,
    ACCOUNT_EDIT_ONE_SUCCESS,
    ACCOUNT_EDIT_ONE_ERROR,

    ACCOUNT_DELETE_ONE,
    ACCOUNT_DELETE_ONE_SUCCESS,
    ACCOUNT_DELETE_ONE_ERROR,

    SYNCHRONIZE_ACCOUNT,
    SYNCHRONIZE_ACCOUNT_SUCCESS,
    SYNCHRONIZE_ACCOUNT_ERROR,


    SMS_GET_LIST ,
    SMS_GET_LIST_SUCCESS,
    SMS_GET_LIST_ERROR,

    SEND_SMS,
    SEND_SMS_SUCCESS,
    SEND_SMS_ERROR,

  } from '../actions';

   // GET SMS LIST
   export const getSmsList = (payload) => ({
    type: SMS_GET_LIST,
    payload
  });
  export const getSmsListSuccess = (payload) => ({
    type: SMS_GET_LIST_SUCCESS,
    payload
  });
  export const getSmsListError = (payload) => ({
    type:     SMS_GET_LIST_ERROR,
    payload
  });

  

  // SEND SMS
  export const sendSms = (payload) => ({
    type: SEND_SMS,
    payload
  });
  export const sendSmsSuccess = (payload) => ({
    type: SEND_SMS_SUCCESS,
    payload
  });
  export const sendSmsError = (payload) => ({
    type:     SEND_SMS_ERROR,
    payload
  });

  
   // CHECK ACCOUNT
   export const checkAccount = (payload) => ({
    type: CHECK_ACCOUNT,
    payload
  });
  export const checkAccountSuccess = (payload) => ({
    type: CHECK_ACCOUNT_SUCCESS,
    payload
  });
  export const checkAccountError = (payload) => ({
    type:  CHECK_ACCOUNT_ERROR,
    payload
  });
  
  //GET History
  export const getSmsHistory = (payload) => ({
    type: SMS_HISTORY_GET,
    payload
  });
  export const getAppoitementsListSuccess = (payload) => ({
    type: SMS_HISTORY_GET_SUCCESS,
    payload
  });
  export const getAppoitementsListError = (payload) => ({
    type:  SMS_HISTORY_GET_ERROR,
    payload
  });

  // GET ACCOUNTS
  
  export const getAccountsList = (payload) => ({
    type: ACCOUNTS_GET_LIST,
    payload
  });
  export const getAccountsListSuccess = (payload) => ({
    type: ACCOUNTS_GET_LIST_SUCCESS,
    payload
  });
  export const getAccountsListError = (payload) => ({
    type:     ACCOUNTS_GET_LIST_ERROR,
    payload
  });

  // GET ACCOUNT
  
  export const getAccount = (payload) => ({
    type: ACCOUNT_GET_ONE,
    payload
  });
  export const getAccountSuccess = (payload) => ({
    type: ACCOUNT_GET_ONE_SUCCESS,
    payload
  });
  export const getAccountError = (payload) => ({
    type:     ACCOUNT_GET_ONE_ERROR,
    payload
  });
  

  // ADD ACCOUNT
  
  export const addAccount = (payload) => ({
    type: ACCOUNT_ADD_ONE,
    payload
  });
  export const addAccountSuccess = (payload) => ({
    type: ACCOUNT_ADD_ONE_SUCCESS,
    payload
  });
  export const addAccountError = (payload) => ({
    type:     ACCOUNT_ADD_ONE_ERROR,
    payload
  });

  // EDIT ACCOUNT
  
  export const editAccount = (payload) => ({
    type: ACCOUNT_EDIT_ONE,
    payload
  });
  export const editAccountSuccess = (payload) => ({
    type: ACCOUNT_EDIT_ONE_SUCCESS,
    payload
  });
  export const editAccountError = (payload) => ({
    type:  ACCOUNT_EDIT_ONE_ERROR,
    payload
  });

  
// DELETE ACCOUNT
export const deleteOneAccount = (payload) => ({
  type: ACCOUNT_DELETE_ONE,
  payload
});
export const deleteOneAccountSuccess = (payload) => ({
  type: ACCOUNT_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOneAccountError = (payload) => ({
  type: ACCOUNT_DELETE_ONE_ERROR,
  payload
});


// SYNCHRONIZE ACCOUNT

export const synchronizeAccount = (payload) => ({
  type: SYNCHRONIZE_ACCOUNT,
  payload
});
export const synchronizeAccountSuccess = (payload) => ({
  type: SYNCHRONIZE_ACCOUNT_SUCCESS,
  payload
});
export const synchronizeAccountError = (payload) => ({
  type:  SYNCHRONIZE_ACCOUNT_ERROR,
  payload
});
  

  