import {
  POST_GET_LIST,
  POST_GET_LIST_SUCCESS,
  POST_GET_LIST_ERROR,


  POST_GET_ONE,
  POST_GET_ONE_SUCCESS,
  POST_GET_ONE_ERROR,

  POST_GET_BY_PARTNER,
  POST_GET_BY_PARTNER_SUCCESS,
  POST_GET_BY_PARTNER_ERROR,

  POST_GET_BY_FILTER,
  POST_GET_BY_FILTER_SUCCESS,
  POST_GET_BY_FILTER_ERROR,

  POST_ADD_ONE,
  POST_ADD_ONE_SUCCESS,
  POST_ADD_ONE_ERROR,

  POST_EDIT_ONE,
  POST_EDIT_ONE_SUCCESS,
  POST_EDIT_ONE_ERROR,

  POST_DELETE_ONE,
  POST_DELETE_ONE_SUCCESS,
  POST_DELETE_ONE_ERROR,



  POST_LIKE,
  POST_LIKE_SUCCESS,
  POST_LIKE_ERROR,



  POST_COMMENT,
  POST_COMMENT_SUCCESS,
  POST_COMMENT_ERROR,

  DELETE_COMMENT,
  DELETE_COMMENT_SUCCESS,
  DELETE_COMMENT_ERROR
} from '../actions';

// GET LIST
export const getPostsList = (payload) => ({
  type: POST_GET_LIST,
  payload
});
export const getPostsListSuccess = (payload) => ({
  type: POST_GET_LIST_SUCCESS,
  payload
});
export const getPostsListError = (payload) => ({
  type: POST_GET_LIST_ERROR,
  payload
});







// GET LIST
export const getOnePost = (payload) => ({
  type: POST_GET_ONE,
  payload
});
export const getOnePostSuccess = (payload) => ({
  type: POST_GET_ONE_SUCCESS,
  payload
});
export const getOnePostError = (payload) => ({
  type: POST_GET_ONE_ERROR,
  payload
});



// GET POSTS BY PARTNER

export const getPostsByPartner = (payload) => ({
  type: POST_GET_BY_PARTNER,
  payload
});
export const getPostsByPartnerSuccess = (payload) => ({
  type: POST_GET_BY_PARTNER_SUCCESS,
  payload
});
export const getPostsByPartnerError = (payload) => ({
  type: POST_GET_BY_PARTNER_ERROR,
  payload
});


// GET POSTS BY FILTER

export const getPostsByFilter = (payload) => ({
  type: POST_GET_BY_FILTER,
  payload
});
export const getPostsByFilterSuccess = (payload) => ({
  type: POST_GET_BY_FILTER_SUCCESS,
  payload
});
export const getPostsByFilterError = (payload) => ({
  type: POST_GET_BY_FILTER_ERROR,
  payload
});





// ADD ONE
export const addPost = (payload) => ({
  type: POST_ADD_ONE,
  payload
});
export const addPostSuccess = (payload) => ({
  type: POST_ADD_ONE_SUCCESS,
  payload
});
export const addPostError = (payload) => ({
  type: POST_ADD_ONE_ERROR,
  payload
});




// EDIT ONE
export const editPost = (payload) => ({
  type: POST_EDIT_ONE,
  payload
});
export const editPostSuccess = (payload) => ({
  type: POST_EDIT_ONE_SUCCESS,
  payload
});
export const editPostError = (payload) => ({
  type: POST_EDIT_ONE_ERROR,
  payload
});




// DELETE ONE
export const deleteOnePost = (payload) => ({
  type: POST_DELETE_ONE,
  payload
});
export const deleteOnePostSuccess = (payload) => ({
  type: POST_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOnePostError = (payload) => ({
  type: POST_DELETE_ONE_ERROR,
  payload
});


// ADD LIKE
export const addLike = (payload) => ({
  type: POST_LIKE,
  payload
});
export const addLikeSuccess = (payload) => ({
  type: POST_LIKE_SUCCESS,
  payload
});
export const addLikeError = (payload) => ({
  type: POST_LIKE_ERROR,
  payload
});


// ADD COMMENT
export const addComment = (payload) => ({
  type: POST_COMMENT,
  payload
});
export const addCommentSuccess = (payload) => ({
  type: POST_COMMENT_SUCCESS,
  payload
});
export const addCommentError = (payload) => ({
  type: POST_COMMENT_ERROR,
  payload
});


// DELETE COMMENT
export const deleteComment = (payload) => ({
  type: DELETE_COMMENT,
  payload
});
export const deleteCommentSuccess = (payload) => ({
  type: DELETE_COMMENT_SUCCESS,
  payload
});
export const deleteCommentError = (payload) => ({
  type: DELETE_COMMENT_ERROR,
  payload
});