import {
  TRAINING_GET_LIST,
	TRAINING_GET_LIST_SUCCESS,
  TRAINING_GET_LIST_ERROR,

  TRAINING_GET_PARTNER_NETWORK,

  TRAINING_SUBSCRIBE,
  TRAINING_SUBSCRIBE_SUCCESS,
  TRAINING_SUBSCRIBE_ERROR,

  TRAINING_GET_ONE,
	TRAINING_GET_ONE_SUCCESS,
  TRAINING_GET_ONE_ERROR,

  TRAINING_ADD_ONE,
  TRAINING_ADD_ONE_SUCCESS,
  TRAINING_ADD_ONE_ERROR,

  TRAINING_EDIT_ONE,
  TRAINING_EDIT_ONE_SUCCESS,
  TRAINING_EDIT_ONE_ERROR,

  TRAINING_DELETE_ONE,
  TRAINING_DELETE_ONE_SUCCESS,
  TRAINING_DELETE_ONE_ERROR
} from '../actions';

  // GET LIST
export const getTrainingsList = (payload) => ({
  type: TRAINING_GET_LIST,
  payload
});
export const getTrainingsListSuccess = (payload) => ({
  type: TRAINING_GET_LIST_SUCCESS,
  payload
});
export const getTrainingsListError = (payload) => ({
  type: TRAINING_GET_LIST_ERROR,
  payload
});







  // GET LIST
export const getOneTraining = (payload) => ({
  type: TRAINING_GET_ONE,
  payload
});
export const getOneTrainingSuccess = (payload) => ({
  type: TRAINING_GET_ONE_SUCCESS,
  payload
});
export const getOneTrainingError = (payload) => ({
  type: TRAINING_GET_ONE_ERROR,
  payload
});


// GET LIST
export const getTrainingsListPartner = (payload) => ({
  type: TRAINING_GET_PARTNER_NETWORK,
  payload
});




// TOGGLE SUBSCRIBE
export const subscribeTraining = (payload) => ({
  type: TRAINING_SUBSCRIBE,
  payload
});
export const subscribeTrainingSuccess = (payload) => ({
  type: TRAINING_SUBSCRIBE_SUCCESS,
  payload
});
export const subscribeTrainingError = (payload) => ({
  type: TRAINING_SUBSCRIBE_ERROR,
  payload
});





  // ADD ONE
export const addTraining = (payload) => ({
  type: TRAINING_ADD_ONE,
  payload
});
export const addTrainingSuccess = (payload) => ({
  type: TRAINING_ADD_ONE_SUCCESS,
  payload
});
export const addTrainingError = (payload) => ({
  type: TRAINING_ADD_ONE_ERROR,
  payload
});




  // EDIT ONE
export const editTraining = (payload) => ({
  type: TRAINING_EDIT_ONE,
  payload
});
export const editTrainingSuccess = (payload) => ({
  type: TRAINING_EDIT_ONE_SUCCESS,
  payload
});
export const editTrainingError = (payload) => ({
  type: TRAINING_EDIT_ONE_ERROR,
  payload
});




  // DELETE ONE
export const deleteOneTraining = (payload) => ({
  type: TRAINING_DELETE_ONE,
  payload
});
export const deleteOneTrainingSuccess = (payload) => ({
  type: TRAINING_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOneTrainingError = (payload) => ({
  type: TRAINING_DELETE_ONE_ERROR,
  payload
});