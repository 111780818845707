import {
  TUTORING_GET_LIST,
  TUTORING_GET_LIST_SUCCESS,
  TUTORING_GET_LIST_ERROR,

  TUTORING_GET_BY_TEACHER,
  TUTORING_GET_BY_TEACHER_SUCCESS,
  TUTORING_GET_BY_TEACHER_ERROR,

  TUTORING_GET_ONE,
  TUTORING_GET_ONE_SUCCESS,
  TUTORING_GET_ONE_ERROR,

  TUTORING_ADD_ONE,
  TUTORING_ADD_ONE_SUCCESS,
  TUTORING_ADD_ONE_ERROR,

  TUTORING_EDIT_ONE,
  TUTORING_EDIT_ONE_SUCCESS,
  TUTORING_EDIT_ONE_ERROR,

  TUTORING_DELETE_ONE,
  TUTORING_DELETE_ONE_SUCCESS,
  TUTORING_DELETE_ONE_ERROR,

  TUTORING_SUBSCRIBE,
  TUTORING_SUBSCRIBE_SUCCESS,
  TUTORING_SUBSCRIBE_ERROR,

  TUTORING_ACCEPT,
  TUTORING_ACCEPT_SUCCESS,
  TUTORING_ACCEPT_ERROR,
  } from '../actions';


    // GET LIST
export const getTutoringsList = (payload) => ({
    type: TUTORING_GET_LIST,
    payload
  });
export const getTutoringsListSuccess = (payload) => ({
    type: TUTORING_GET_LIST_SUCCESS,
    payload
  });
export const getTutoringsListError = (payload) => ({
    type: TUTORING_GET_LIST_ERROR,
    payload
  });


    // GET LIST BY TEACHER 
export const getTutoringsByTeacher = (payload) => ({
      type: TUTORING_GET_BY_TEACHER,
      payload
    });
export const getTutoringsByTeacherSuccess = (payload) => ({
      type: TUTORING_GET_BY_TEACHER_SUCCESS,
      payload
    });
export const getTutoringsByTeacherError = (payload) => ({
      type: TUTORING_GET_BY_TEACHER_ERROR,
      payload
    });
  

 // GET ONE
export const getOneTutoring = (payload) => ({
  type: TUTORING_GET_ONE,
  payload
});
export const getOneTutoringSuccess = (payload) => ({
  type: TUTORING_GET_ONE_SUCCESS,
  payload
});
export const getOneTutoringError = (payload) => ({
  type: TUTORING_GET_ONE_ERROR,
  payload
});

// ADD ONE
export const addTutoring = (payload) => ({
    type: TUTORING_ADD_ONE,
    payload
  });
export const addTutoringSuccess = (payload) => ({
    type: TUTORING_ADD_ONE_SUCCESS,
    payload
  });
export const addTutoringError = (payload) => ({
    type: TUTORING_ADD_ONE_ERROR,
    payload
  });


  // EDIT ONE
export const editOneTutoring = (payload) => ({
    type: TUTORING_EDIT_ONE,
    payload
  });
export const editOneTutoringSuccess = (payload) => ({
    type: TUTORING_EDIT_ONE_SUCCESS,
    payload
  });
export const editOneTutoringError = (payload) => ({
    type: TUTORING_EDIT_ONE_ERROR,
    payload
  });


  // DELETE ONE
export const deleteOneTutoring = (payload) => ({
    type: TUTORING_DELETE_ONE,
    payload
  });
export const deleteOneTutoringSuccess = (payload) => ({
    type: TUTORING_DELETE_ONE_SUCCESS,
    payload
  });
export const deleteOneTutoringError = (payload) => ({
    type: TUTORING_DELETE_ONE_ERROR,
    payload
  });


// TOGGLE SUBSCRIBE
export const subscribeTutoring = (payload) => ({
  type: TUTORING_SUBSCRIBE,
  payload
});
export const subscribeTutoringSuccess = (payload) => ({
  type: TUTORING_SUBSCRIBE_SUCCESS,
  payload
});
export const subscribeTutoringError = (payload) => ({
  type: TUTORING_SUBSCRIBE_ERROR,
  payload
});

//ACCEPT
export const acceptTutoring = (payload) => ({
  type: TUTORING_ACCEPT,
  payload
});
export const acceptTutoringSuccess =(payload) =>({
  type:TUTORING_ACCEPT_SUCCESS,
  payload
});
export const acceptTutoringError =(payload) =>({
  type:TUTORING_ACCEPT_ERROR,
  payload
});
