import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import { EVENT_GET_LIST, EVENT_SUBSCRIBE, EVENT_GET_PARTNER_NETWORK, EVENT_ADD_ONE, EVENT_GET_ONE, EVENT_EDIT_ONE, EVENT_DELETE_ONE
} from "../actions";

import {
  subscribeEventSuccess,
  subscribeEventError,

  getEventsListSuccess,
  getEventsListError,

  getOneEventSuccess,
  getOneEventError,

  addEventSuccess,
  addEventError,

  editEventSuccess,
  editEventError,
  deleteOneEventSuccess,
  deleteOneEventError,
} from "./actions";


  //GET LIST
const getEventsListRequest = async (payload) => {  
  return await axios.get(api.events+`/list/${payload.inst_id}/${payload.offset}/${payload.limit}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getEventsList({payload}) {
  try {
    const ret = yield call(getEventsListRequest, payload);
    
    if (ret && ret.status==200)
    yield put(getEventsListSuccess({events: ret.data.events || [], count: ret.data.count || 0}));
    
    else yield put(getEventsListError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getEventsListError({error, message: (error) ? error.message : ''}));
  }
}



//PARTNER NETWORK EVENTS
const getEventsListPartnerRequest = async (payload) => {  
  return await axios.get(api.events+`/partner-network/${payload.partner_id}/${payload.offset}/${payload.limit}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getEventsListPartner({payload}) {
  try {
    const ret = yield call(getEventsListPartnerRequest, payload);
    
    if (ret && ret.status==200)
    yield put(getEventsListSuccess({events: ret.data.events || [], count: ret.data.count || 0}));
    
    else yield put(getEventsListError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getEventsListError({error, message: (error) ? error.message : ''}));
  }
}



//GET ONE
const getOneEventsRequest = async (payload) => {
  return await axios.get(api.events+`/get-one/${payload._id}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getOneEvent({payload}) {  
  try {
    const ret = yield call(getOneEventsRequest, payload);

    if (ret && ret.status==200)
    yield put(getOneEventSuccess({event: ret.data.event}));
    
    else yield put(getOneEventError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getOneEventError({error, message: (error) ? error.message : ''}));
  }
}



//SUBSCRIBE
const subscribeEventRequest = async (payload) => {
  return await axios.post(api.events+`/subscribe/${payload.event_id}`,{
    partner: payload.partner
  }, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* subscribeEvent({payload}) {  
  try {
    const ret = yield call(subscribeEventRequest, payload);

    if (ret && ret.status==200){
      const events = payload.events
      const event = ret.data.event
      if(events && events[payload.index]) events[payload.index] = event
      yield put(subscribeEventSuccess({index: payload.index, events, event }));
    }
    
    else yield put(subscribeEventError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(subscribeEventError({error, message: (error) ? error.message : ''}));
  }
}




  //ADD ONE
const addEventRequest = async (payload) => {
  const data = payload.data

  const frm = new FormData();
  frm.append("name", data.name)
  frm.append("cover", data.cover)
  frm.append("type", data.type)
  frm.append("date_start", data.date_start)
  frm.append("date_end", data.date_end)
  frm.append("deadline_subscription", data.deadline_subscription)
  frm.append("address", data.address)
  frm.append("program", data.program)
  frm.append("desc", data.desc)

  if(data.user && data.user!="") frm.append("user", data.user)
  if(data.inst_id && data.inst_id!="") frm.append("inst_id", data.inst_id)
  if(data.partner && data.partner!="") frm.append("partner", data.partner)

  return await axios.post(api.events, frm, { headers: {
    Authorization: payload.token, 'Content-Type': 'multipart/form-data',
  } })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* addEvent({payload}) {  
  try {
    const ret = yield call(addEventRequest, payload);
    
    if (ret && ret.status==201){
      // const { history } = payload;
      // if(history) history.push('/institution/events')
      yield put(addEventSuccess({event: ret.data.event}));
    }
    else yield put(addEventError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(addEventError({error, message: (error) ? error.message : ''}));
  }
}





  //EDIT ONE
const editOneEventRequest = async (payload) => {
  const data = payload.data

  const frm = new FormData();
  frm.append("name", data.name)
  frm.append("cover", data.cover)
  frm.append("type", data.type)
  frm.append("date_start", data.date_start)
  frm.append("date_end", data.date_end)
  frm.append("deadline_subscription", data.deadline_subscription)
  frm.append("address", data.address)
  frm.append("program", data.program)
  frm.append("desc", data.desc)

  if(data.user && data.user!="") frm.append("user", data.user)
  if(data.inst_id && data.inst_id!="") frm.append("inst_id", data.inst_id)

  return await axios.patch(`${api.events}/edit-one/${payload._id}`, frm, { headers: {
    Authorization: payload.token, 'Content-Type': 'multipart/form-data',
  } })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* editOneEvent({payload}) {  
  try {
    const ret = yield call(editOneEventRequest, payload);
    
    if (ret && ret.status==200){
      // const { history } = payload;
      // if(history) history.push('/institution/events')
      yield put(editEventSuccess({event: ret.data.event}));
    }
    else yield put(editEventError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(editEventError({error, message: (error) ? error.message : ''}));
  }
}


  // DELETE ONE
const deleteOneEventRequest = async (payload) => {
  return await axios.delete(`${api.events}/delete-one/${payload._id}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* deleteOneEvent({payload}) {  
  try {
    const ret = yield call(deleteOneEventRequest, payload);
    
    if (ret && ret.status==202){
      const { history, index } = payload;

      if(history) history.push('/app/my-institutions/events')
      yield put(deleteOneEventSuccess({index}));
    }
    else yield put(deleteOneEventError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOneEventError({error, message: (error) ? error.message : ''}));
  }
}


//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(EVENT_GET_LIST, getEventsList);
}

export function* watchSubscribe() {
  yield takeEvery(EVENT_SUBSCRIBE, subscribeEvent);
}

export function* watchGetListPartner() {
  yield takeEvery(EVENT_GET_PARTNER_NETWORK, getEventsListPartner);
}

export function* watchGetOne() {
  yield takeEvery(EVENT_GET_ONE, getOneEvent);
}

export function* watchEditOne() {
  yield takeEvery(EVENT_EDIT_ONE, editOneEvent);
}

export function* watchAddOne() {
  yield takeEvery(EVENT_ADD_ONE, addEvent);
}

export function* watchDeleteOne() {
  yield takeEvery(EVENT_DELETE_ONE, deleteOneEvent);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchSubscribe),
    fork(watchGetListPartner),
    fork(watchGetOne),
    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchDeleteOne)
  ]);
}
