import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import { GROUP_GET_LIST, GROUP_SUBSCRIBE, GROUP_GET_PARTNER_NETWORK, GROUP_ADD_ONE, GROUP_GET_ONE, GROUP_EDIT_ONE, GROUP_DELETE_ONE
} from "../actions";

import {
  subscribeGroupSuccess,
  subscribeGroupError,

  getGroupsListSuccess,
  getGroupsListError,

  getOneGroupSuccess,
  getOneGroupError,

  addGroupSuccess,
  addGroupError,

  editGroupSuccess,
  editGroupError,
  deleteOneGroupSuccess,
  deleteOneGroupError,
} from "./actions";


  //GET LIST
const getGroupsListRequest = async (payload) => {  
  return await axios.get(api.groups+`/list/${payload.inst_id}/${payload.offset}/${payload.limit}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getGroupsList({payload}) {
  try {
    const ret = yield call(getGroupsListRequest, payload);
    
    if (ret && ret.status==200)
    yield put(getGroupsListSuccess({groups: ret.data.groups || [], count: ret.data.count || 0}));
    
    else yield put(getGroupsListError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getGroupsListError({error, message: (error) ? error.message : ''}));
  }
}



//PARTNER NETWORK GROUPS
const getGroupsListPartnerRequest = async (payload) => {  
  return await axios.get(api.groups+`/partner-network/${payload.partner_id}/${payload.offset}/${payload.limit}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getGroupsListPartner({payload}) {
  try {
    const ret = yield call(getGroupsListPartnerRequest, payload);
    
    if (ret && ret.status==200)
    yield put(getGroupsListSuccess({groups: ret.data.groups || [], count: ret.data.count || 0}));
    
    else yield put(getGroupsListError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getGroupsListError({error, message: (error) ? error.message : ''}));
  }
}



//GET ONE
const getOneGroupsRequest = async (payload) => {
  return await axios.get(api.groups+`/get-one/${payload._id}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getOneGroup({payload}) {  
  try {
    const ret = yield call(getOneGroupsRequest, payload);

    if (ret && ret.status==200)
    yield put(getOneGroupSuccess({group: ret.data.group}));
    
    else yield put(getOneGroupError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getOneGroupError({error, message: (error) ? error.message : ''}));
  }
}



//SUBSCRIBE
const subscribeGroupRequest = async (payload) => {
  return await axios.post(api.groups+`/subscribe/${payload.group_id}`,{
    partner: payload.partner
  }, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* subscribeGroup({payload}) {  
  try {
    const ret = yield call(subscribeGroupRequest, payload);

    if (ret && ret.status==200){
      const groups = payload.groups
      const group = ret.data.group
      if(groups && groups[payload.index]) groups[payload.index] = group
      yield put(subscribeGroupSuccess({index: payload.index, groups, group }));
    }
    
    else yield put(subscribeGroupError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(subscribeGroupError({error, message: (error) ? error.message : ''}));
  }
}




  //ADD ONE
const addGroupRequest = async (payload) => {
  const data = payload.data

  const frm = new FormData();
  frm.append("name", data.name)
  frm.append("avatar", data.avatar)
  frm.append("topic", data.topic)
  frm.append("is_public", (data.is_public))

  if(data.user && data.user!="") frm.append("user", data.user)
  if(data.inst_id && data.inst_id!="") frm.append("inst_id", data.inst_id)
  if(data.partner && data.partner!="") frm.append("partner", data.partner)

  return await axios.post(api.groups, frm, { headers: {
    Authorization: payload.token, 'Content-Type': 'multipart/form-data',
  } })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* addGroup({payload}) {  
  try {
    const ret = yield call(addGroupRequest, payload);
    
    if (ret && ret.status==201){
      // const { history } = payload;
      // if(history) history.push('/institution/groups')
      yield put(addGroupSuccess({group: ret.data.group}));
    }
    else yield put(addGroupError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(addGroupError({error, message: (error) ? error.message : ''}));
  }
}





  //EDIT ONE
const editOneGroupRequest = async (payload) => {
  const data = payload.data

  const frm = new FormData();
  frm.append("name", data.name)
  frm.append("avatar", data.avatar)
  frm.append("topic", data.topic)
  frm.append("is_public", (data.is_public))

  if(data.user && data.user!="") frm.append("user", data.user)
  if(data.inst_id && data.inst_id!="") frm.append("inst_id", data.inst_id)

  return await axios.patch(`${api.groups}/edit-one/${payload._id}`, frm, { headers: {
    Authorization: payload.token, 'Content-Type': 'multipart/form-data',
  } })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* editOneGroup({payload}) {  
  try {
    const ret = yield call(editOneGroupRequest, payload);
    
    if (ret && ret.status==200){
      // const { history } = payload;
      // if(history) history.push('/institution/groups')
      yield put(editGroupSuccess({group: ret.data.group}));
    }
    else yield put(editGroupError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(editGroupError({error, message: (error) ? error.message : ''}));
  }
}


  // DELETE ONE
const deleteOneGroupRequest = async (payload) => {
  return await axios.delete(`${api.groups}/delete-one/${payload._id}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* deleteOneGroup({payload}) {  
  try {
    const ret = yield call(deleteOneGroupRequest, payload);
    
    if (ret && ret.status==202){
      const { history, index } = payload;

      if(history) history.push('/app/my-institutions/groups')
      yield put(deleteOneGroupSuccess({index}));
    }
    else yield put(deleteOneGroupError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOneGroupError({error, message: (error) ? error.message : ''}));
  }
}


//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(GROUP_GET_LIST, getGroupsList);
}

export function* watchSubscribe() {
  yield takeEvery(GROUP_SUBSCRIBE, subscribeGroup);
}

export function* watchGetListPartner() {
  yield takeEvery(GROUP_GET_PARTNER_NETWORK, getGroupsListPartner);
}

export function* watchGetOne() {
  yield takeEvery(GROUP_GET_ONE, getOneGroup);
}

export function* watchEditOne() {
  yield takeEvery(GROUP_EDIT_ONE, editOneGroup);
}

export function* watchAddOne() {
  yield takeEvery(GROUP_ADD_ONE, addGroup);
}

export function* watchDeleteOne() {
  yield takeEvery(GROUP_DELETE_ONE, deleteOneGroup);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchSubscribe),
    fork(watchGetListPartner),
    fork(watchGetOne),
    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchDeleteOne)
  ]);
}
