import {
	FEE_COMPOSITION_GET_LIST,
	FEE_COMPOSITION_GET_LIST_SUCCESS,
  FEE_COMPOSITION_GET_LIST_ERROR,
  FEE_COMPOSITION_GET_ONE,
	FEE_COMPOSITION_GET_ONE_SUCCESS,
  FEE_COMPOSITION_GET_ONE_ERROR,
  FEE_COMPOSITION_ADD_ONE,
  FEE_COMPOSITION_ADD_ONE_SUCCESS,
  FEE_COMPOSITION_ADD_ONE_ERROR,
  FEE_COMPOSITION_EDIT_ONE,
  FEE_COMPOSITION_EDIT_ONE_SUCCESS,
  FEE_COMPOSITION_EDIT_ONE_ERROR,
  FEE_COMPOSITION_DELETE_ONE,
  FEE_COMPOSITION_DELETE_ONE_SUCCESS,
  FEE_COMPOSITION_DELETE_ONE_ERROR
} from '../../actions';
import { NotificationManager } from '../../../components/common/react-notifications';
import  notificationMessages  from '../../../lang/locales/notificationMsg'

const INIT_STATE = {
	fee_compositions: [],
  count: 0,
  fee_composition: null,
  error: '',
};

export default (state = INIT_STATE, action) => {
  const messages = notificationMessages()
	switch (action.type) {
		case FEE_COMPOSITION_GET_LIST:
      return { ...state, loading: true, error: '', 
      count: 0
    };
    case FEE_COMPOSITION_GET_LIST_SUCCESS:
      return { ...state, loading: false, 
        fee_compositions: action.payload.fee_compositions || [],
        count: action.payload.count || 0
      };
		case FEE_COMPOSITION_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };

      // GET ONE
    case FEE_COMPOSITION_GET_ONE:
      return { ...state, loading: true, error: '', fee_composition: null};
    case FEE_COMPOSITION_GET_ONE_SUCCESS:
      return { ...state, loading: false, error: '',
        fee_composition: action.payload.fee_composition
      };
    case FEE_COMPOSITION_GET_ONE_ERROR:
      return { ...state, loading: false, error: action.payload.message };




      // ADD ONE
    case FEE_COMPOSITION_ADD_ONE:
      //console.log('reducer add one');
      
      return { ...state, loading: true, error: ''};
    case FEE_COMPOSITION_ADD_ONE_SUCCESS:    
      NotificationManager.success(messages["Added"], messages["Success"],3000, null, null, '');
      return { ...state, loading: false, 
        fee_compositions: [action.payload.fee_composition, ...state.fee_compositions]
      };
    case FEE_COMPOSITION_ADD_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };





    
      // EDIT ONE
    case FEE_COMPOSITION_EDIT_ONE:
      return { ...state, loading: true, error: ''};
    case FEE_COMPOSITION_EDIT_ONE_SUCCESS:    
      NotificationManager.success(messages["Edited"], "Success", 3000, null, null, '');
      return { ...state, loading: false, error: '',
      fee_composition: action.payload.fee_composition
      };
    case FEE_COMPOSITION_EDIT_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

    




      // DELETE ONE
    case FEE_COMPOSITION_DELETE_ONE:
      return { ...state, loading: true, error: ''};
    case FEE_COMPOSITION_DELETE_ONE_SUCCESS:  
      const fee_compositions = state.fee_compositions 
      if(action.payload.index!=null && action.payload.index!=undefined)
      fee_compositions.splice(action.payload.index, 1)

      return { ...state, loading: false, error: '',
        fee_compositions
      };
    case FEE_COMPOSITION_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

    
		default: return { ...state };
	}
}
