import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import { COURSE_GET_LIST, COURSE_SUBSCRIBE, COURSE_GET_PARTNER_NETWORK, COURSE_ADD_ONE, COURSE_GET_ONE, COURSE_EDIT_ONE, COURSE_DELETE_ONE
} from "../actions";

import {
  subscribeCourseSuccess,
  subscribeCourseError,

  getCoursesListSuccess,
  getCoursesListError,

  getOneCourseSuccess,
  getOneCourseError,

  addCourseSuccess,
  addCourseError,

  editCourseSuccess,
  editCourseError,
  deleteOneCourseSuccess,
  deleteOneCourseError,
} from "./actions";


  //GET LIST
const getCoursesListRequest = async (payload) => {
  return await axios.get(api.courses+`/list/${payload.partner_id}/${payload.offset}/${payload.limit}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getCoursesList({payload}) {
  try {
    const ret = yield call(getCoursesListRequest, payload);
    
    if (ret && ret.status==200)
    yield put(getCoursesListSuccess({courses: ret.data.courses || [], count: ret.data.count || 0}));
    
    else yield put(getCoursesListError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getCoursesListError({error, message: (error) ? error.message : ''}));
  }
}


//PARTNER NETWORK COURSES
const getCoursesListPartnerRequest = async (payload) => {  
  return await axios.get(api.courses+`/partner-network/${payload.partner_id}/${payload.offset}/${payload.limit}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getCoursesListPartner({payload}) {
  try {
    const ret = yield call(getCoursesListPartnerRequest, payload);
    
    if (ret && ret.status==200)
    yield put(getCoursesListSuccess({courses: ret.data.courses || [], count: ret.data.count || 0}));
    
    else yield put(getCoursesListError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getCoursesListError({error, message: (error) ? error.message : ''}));
  }
}




//GET ONE
const getOneCoursesRequest = async (payload) => {
  return await axios.get(api.courses+`/get-one/${payload._id}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getOneCourse({payload}) {  
  try {
    const ret = yield call(getOneCoursesRequest, payload);

    if (ret && ret.status==200)
    yield put(getOneCourseSuccess({course: ret.data.course}));
    
    else yield put(getOneCourseError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getOneCourseError({error, message: (error) ? error.message : ''}));
  }
}






//SUBSCRIBE
const subscribeCourseRequest = async (payload) => {
  return await axios.post(api.courses+`/subscribe/${payload.course_id}`,{
    partner: payload.partner
  }, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* subscribeCourse({payload}) {  
  try {
    const ret = yield call(subscribeCourseRequest, payload);

    if (ret && ret.status==200){
      const courses = payload.courses
      const course = ret.data.course
      if(courses && courses[payload.index]) courses[payload.index] = course
      yield put(subscribeCourseSuccess({index: payload.index, courses, course }));
    }
    
    else yield put(subscribeCourseError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(subscribeCourseError({error, message: (error) ? error.message : ''}));
  }
}






  //ADD ONE
const addCourseRequest = async (payload) => {
  return await axios.post(api.courses, payload.data, { headers: {
    Authorization: payload.token, 'Content-Type': 'multipart/form-data',
  } })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* addCourse({payload}) {  
  try {
    const ret = yield call(addCourseRequest, payload);
    
    if (ret && ret.status==201){
      // const { history } = payload;

      // if(history) history.push('/app/my-institutions/courses')
      yield put(addCourseSuccess({course: ret.data.course}));
    }
    else yield put(addCourseError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(addCourseError({error, message: (error) ? error.message : ''}));
  }
}





  //EDIT ONE
const editOneCourseRequest = async (payload) => {
  return await axios.patch(`${api.courses}/edit-one/${payload._id}`, payload.data, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* editOneCourse({payload}) {  
  try {
    const ret = yield call(editOneCourseRequest, payload);
    
    if (ret && ret.status==200){
      // const { history } = payload;

      // if(history) history.push('/app/my-institutions/courses')
      yield put(editCourseSuccess({course: ret.data.course}));
    }
    else yield put(editCourseError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(editCourseError({error, message: (error) ? error.message : ''}));
  }
}


  // DELETE ONE
const deleteOneCourseRequest = async (payload) => {
  return await axios.delete(`${api.courses}/delete-one/${payload._id}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* deleteOneCourse({payload}) {  
  try {
    const ret = yield call(deleteOneCourseRequest, payload);
    
    if (ret && ret.status==202){
      const { history, index } = payload;

      if(history) history.push('/app/my-institutions/courses')
      yield put(deleteOneCourseSuccess({index}));
    }
    else yield put(deleteOneCourseError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOneCourseError({error, message: (error) ? error.message : ''}));
  }
}


//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(COURSE_GET_LIST, getCoursesList);
}

export function* watchSubscribe() {
  yield takeEvery(COURSE_SUBSCRIBE, subscribeCourse);
}

export function* watchGetListPartner() {
  yield takeEvery(COURSE_GET_PARTNER_NETWORK, getCoursesListPartner);
}

export function* watchGetOne() {
  yield takeEvery(COURSE_GET_ONE, getOneCourse);
}

export function* watchEditOne() {
  yield takeEvery(COURSE_EDIT_ONE, editOneCourse);
}

export function* watchAddOne() {
  yield takeEvery(COURSE_ADD_ONE, addCourse);
}

export function* watchDeleteOne() {
  yield takeEvery(COURSE_DELETE_ONE, deleteOneCourse);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetOne),
    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchDeleteOne),
    fork(watchGetListPartner),
    fork(watchSubscribe),
  ]);
}
