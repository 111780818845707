import {
    SESSION_GET_LIST,
    SESSION_GET_LIST_SUCCESS,
    SESSION_GET_LIST_ERROR,

    SESSION_GET_ONE,
    SESSION_GET_ONE_SUCCESS,
    SESSION_GET_ONE_ERROR,
    

    SESSION_GET_BY_FILTER,
    SESSION_GET_BY_FILTER_SUCCESS,
    SESSION_GET_BY_FILTER_ERROR,
    
    SESSION_ADD_ONE,
    SESSION_ADD_ONE_SUCCESS,
    SESSION_ADD_ONE_ERROR,

    SESSION_EDIT_ONE,
    SESSION_EDIT_ONE_SUCCESS,
    SESSION_EDIT_ONE_ERROR,

    SESSION_DELETE_ONE,
    SESSION_DELETE_ONE_SUCCESS,
    SESSION_DELETE_ONE_ERROR

  } from '../actions';
import { NotificationManager } from '../../components/common/react-notifications';
import  notificationMessages  from '../../lang/locales/notificationMsg'

const INIT_STATE = {
	sessions: [],
  count: 0,
  session: null,
  error: '',
};

export default (state = INIT_STATE, action) => {
  const messages = notificationMessages()
	switch (action.type) {


        //GET LIST 
        case SESSION_GET_LIST:
        return { ...state, loading: true, error: '', 
        count: 0
        };
        case SESSION_GET_LIST_SUCCESS:
        return { ...state, loading: false, 
          sessions: action.payload.sessions || [],
          count: action.payload.count || 0
        };
        case SESSION_GET_LIST_ERROR:
        return { ...state, loading: false, error: action.payload.message };

          // GET ONE
        case SESSION_GET_ONE:
        return { ...state, loading: true, error: ''};
        case SESSION_GET_ONE_SUCCESS:
        return { ...state, loading: false, error: '',
        session: action.payload.session
        };
        case SESSION_GET_ONE_ERROR:
        return { ...state, loading: false, error: action.payload.message };



         // GET BY FILTER
        case SESSION_GET_BY_FILTER:
      
        return { ...state, loading: true, error: ''};
        case SESSION_GET_BY_FILTER_SUCCESS:
        return { ...state, loading: false, error: '',
         sessions: action.payload.sessions || [],
         count: action.payload.count || 0
        };
        case SESSION_GET_BY_FILTER_ERROR:
      
        return { ...state, loading: false, error: action.payload.message };



        
           // ADD ONE
        case SESSION_ADD_ONE:
        return { ...state, loading: true, error: ''};
        case SESSION_ADD_ONE_SUCCESS:    
        NotificationManager.success(messages["Added"], messages["Success"],3000, null, null, '');
        return { ...state, loading: false, 
            sessions: [action.payload.session, ...state.sessions]
        };
        case SESSION_ADD_ONE_ERROR:
          NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
        return { ...state, loading: false, error: action.payload.message };

         
           // EDIT ONE
        case SESSION_EDIT_ONE:
        return { ...state, loading: true, error: ''};
        case SESSION_EDIT_ONE_SUCCESS:    
        NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
        return { ...state, loading: false, error: '',
        session: action.payload.session
        };
        case SESSION_EDIT_ONE_ERROR:
          NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
        return { ...state, loading: false, error: action.payload.message };



         // DELETE ONE
        case SESSION_DELETE_ONE:
        return { ...state, loading: true, error: ''};
        case SESSION_DELETE_ONE_SUCCESS:  
        const sessions = state.sessions 
        if(action.payload.index!=null && action.payload.index!=undefined)
        sessions.splice(action.payload.index, 1)

        return { ...state, loading: false, error: '',
        sessions
        };
        case SESSION_DELETE_ONE_ERROR:
          NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
        return { ...state, loading: false, error: action.payload.message };

  
      default: return { ...state };
    }
}