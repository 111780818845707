import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { api } from "../../../constants/defaultValues";
import axios from "axios";

import { CONFIGS_LEVEL_GET_LIST,CONFIGS_LEVEL_GET_ONE,CONFIGS_LEVEL_ADD_ONE,CONFIGS_LEVEL_EDIT_ONE,CONFIGS_LEVEL_DELETE_ONE
} from "../../actions";
import {
    getConfigsLevelListSuccess,
    getConfigsLevelListError,


    getOneConfigslevelSuccess,
    getOneConfigslevelError,

    addConfigsLevelSuccess,
    addConfigsLevelError,

    editOneConfigsLevelSuccess,
    editOneConfigsLevelError,

    deleteOneConfigsLevelSuccess,
    deleteOneConfigsLevelError,
  } from "./actions";


 //GET LIST
 const getConfigsLevelListRequest = async (payload) => {
    return await axios.get(api.configs+`/levels/list/${payload.offset}/${payload.limit}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* getConfigsLevelList({payload}) {
    try {
      const ret = yield call(getConfigsLevelListRequest, payload);
      
      if (ret && ret.status==200)
      yield put(getConfigsLevelListSuccess({levels: ret.data.levels || [], count: ret.data.count || 0}));
       
    
      else yield put(getConfigsLevelListError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      
      yield put(getConfigsLevelListError({error, message: (error) ? error.message : ''}));
    }
  }


//GET ONE
const getOneConfigslevelRequest = async (payload) => {
    return await axios.get(api.configs+`/levels/get-one/${payload._id}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
  function* getOneConfigslevel({payload}) {
    try {
      const ret = yield call(getOneConfigslevelRequest, payload);
  
      if (ret && ret.status==200) yield put(getOneConfigslevelSuccess({level: ret.data.level}));
      else yield put(getOneConfigslevelError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(getOneConfigslevelError({error, message: (error) ? error.message : ''}));
    }
  }

//ADD ONE
const addConfigsLevelRequest = async (payload) => {
    return await axios.post(api.configs+`/levels`, payload.data, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
  function* addConfigsLevel({payload}) {  
    try {
      const ret = yield call(addConfigsLevelRequest, payload);
      
      if (ret && ret.status==201){
        const { history } = payload;
  
        if(history) history.push('/admin/settings/education-levels')
        yield put(addConfigsLevelSuccess({level: ret.data.level}));
      }
      else yield put(addConfigsLevelError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(addConfigsLevelError({error, message: (error) ? error.message : ''}));
    }
  }

 //EDIT ONE
 const editOneConfigsLevelRequest = async (payload) => {
    return await axios.patch(`${api.configs}/levels/edit-one/${payload._id}`, payload.data, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* editOneConfigsLevel({payload}) {  
    try {
      const ret = yield call(editOneConfigsLevelRequest, payload);
      
      if (ret && ret.status==200){
        const { history } = payload;
  
        if(history) history.push('/admin/settings/education-levels')
        yield put(editOneConfigsLevelSuccess({level: ret.data.level}));
      }
      else yield put(editOneConfigsLevelError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(editOneConfigsLevelError({error, message: (error) ? error.message : ''}));
    }
  }

  
  // DELETE ONE
const deleteOneConfigsLevelRequest = async (payload) => {
    return await axios.delete(`${api.configs}/levels/delete-one/${payload._id}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* deleteOneConfigsLevel({payload}) {  
    try {
      const ret = yield call(deleteOneConfigsLevelRequest, payload);
      
      if (ret && ret.status==202){
        const { history, index } = payload;
  
        if(history) history.push('/admin/settings/education-levels')
        yield put(deleteOneConfigsLevelSuccess({index}));
      }
      else yield put(deleteOneConfigsLevelError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(deleteOneConfigsLevelError({error, message: (error) ? error.message : ''}));
    }
  }


//////// WATCHERS
export function* watchGetList() {
    yield takeEvery(CONFIGS_LEVEL_GET_LIST, getConfigsLevelList);
  }
  
  
export function* watchGetOne() {
    yield takeEvery(CONFIGS_LEVEL_GET_ONE, getOneConfigslevel);
  }
  
export function* watchEditOne() {
    yield takeEvery(CONFIGS_LEVEL_EDIT_ONE, editOneConfigsLevel);
  }
  
export function* watchAddOne() {
    yield takeEvery(CONFIGS_LEVEL_ADD_ONE, addConfigsLevel);
  }
  
export function* watchDeleteOne() {
    yield takeEvery(CONFIGS_LEVEL_DELETE_ONE, deleteOneConfigsLevel);
  }
  
  export default function* rootSaga() {
    yield all([
      fork(watchGetList),
      fork(watchGetOne),
      fork(watchEditOne),
      fork(watchAddOne),
      fork(watchDeleteOne)
    ]);
  }