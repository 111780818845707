import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import { TRAINING_GET_LIST, TRAINING_SUBSCRIBE, TRAINING_GET_PARTNER_NETWORK, TRAINING_ADD_ONE, TRAINING_GET_ONE, TRAINING_EDIT_ONE, TRAINING_DELETE_ONE
} from "../actions";

import {
  subscribeTrainingSuccess,
  subscribeTrainingError,

  getTrainingsListSuccess,
  getTrainingsListError,

  getOneTrainingSuccess,
  getOneTrainingError,

  addTrainingSuccess,
  addTrainingError,

  editTrainingSuccess,
  editTrainingError,
  deleteOneTrainingSuccess,
  deleteOneTrainingError,
} from "./actions";


  //GET LIST
const getTrainingsListRequest = async (payload) => {
  return await axios.get(api.trainings+`/list/${payload.inst_id}/${payload.offset}/${payload.limit}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getTrainingsList({payload}) {
  try {
    const ret = yield call(getTrainingsListRequest, payload);
    
    if (ret && ret.status==200)
    yield put(getTrainingsListSuccess({trainings: ret.data.trainings || [], count: ret.data.count || 0}));
    
    else yield put(getTrainingsListError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getTrainingsListError({error, message: (error) ? error.message : ''}));
  }
}


//PARTNER NETWORK TRAININGS
const getTrainingsListPartnerRequest = async (payload) => {  
  return await axios.get(api.trainings+`/partner-network/${payload.partner_id}/${payload.offset}/${payload.limit}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getTrainingsListPartner({payload}) {
  try {
    const ret = yield call(getTrainingsListPartnerRequest, payload);
    //console.log("getTrainingsListPartner : ",ret);
    
    
    if (ret && ret.status==200)
    yield put(getTrainingsListSuccess({trainings: ret.data.trainings || [], count: ret.data.count || 0}));
    
    else yield put(getTrainingsListError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getTrainingsListError({error, message: (error) ? error.message : ''}));
  }
}




//GET ONE
const getOneTrainingsRequest = async (payload) => {
  return await axios.get(api.trainings+`/get-one/${payload._id}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getOneTraining({payload}) {  
  try {
    const ret = yield call(getOneTrainingsRequest, payload);

    if (ret && ret.status==200)
    yield put(getOneTrainingSuccess({training: ret.data.training}));
    
    else yield put(getOneTrainingError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getOneTrainingError({error, message: (error) ? error.message : ''}));
  }
}






//SUBSCRIBE
const subscribeTrainingRequest = async (payload) => {
  return await axios.post(api.trainings+`/subscribe/${payload.training_id}`,{
    partner: payload.partner
  }, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* subscribeTraining({payload}) {  
  try {
    const ret = yield call(subscribeTrainingRequest, payload);

    if (ret && ret.status==200){
      const trainings = payload.trainings
      const training = ret.data.training
      if(trainings && trainings[payload.index]) trainings[payload.index] = training
      yield put(subscribeTrainingSuccess({index: payload.index, trainings, training }));
    }
    
    else yield put(subscribeTrainingError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(subscribeTrainingError({error, message: (error) ? error.message : ''}));
  }
}






  //ADD ONE
const addTrainingRequest = async (payload) => {
  return await axios.post(api.trainings, payload.data, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* addTraining({payload}) {  
  try {
    const ret = yield call(addTrainingRequest, payload);
    
    if (ret && ret.status==201){
      // const { history } = payload;

      // if(history) history.push('/app/my-institutions/trainings')
      yield put(addTrainingSuccess({training: ret.data.training}));
    }
    else yield put(addTrainingError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(addTrainingError({error, message: (error) ? error.message : ''}));
  }
}





  //EDIT ONE
const editOneTrainingRequest = async (payload) => {
  return await axios.patch(`${api.trainings}/edit-one/${payload._id}`, payload.data, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* editOneTraining({payload}) {  
  try {
    const ret = yield call(editOneTrainingRequest, payload);
    
    if (ret && ret.status==200){
      // const { history } = payload;

      // if(history) history.push('/app/my-institutions/trainings')
      yield put(editTrainingSuccess({training: ret.data.training}));
    }
    else yield put(editTrainingError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(editTrainingError({error, message: (error) ? error.message : ''}));
  }
}


  // DELETE ONE
const deleteOneTrainingRequest = async (payload) => {
  return await axios.delete(`${api.trainings}/delete-one/${payload._id}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* deleteOneTraining({payload}) {  
  try {
    const ret = yield call(deleteOneTrainingRequest, payload);
    
    if (ret && ret.status==202){
      const { history, index } = payload;

      if(history) history.push('/app/my-institutions/trainings')
      yield put(deleteOneTrainingSuccess({index}));
    }
    else yield put(deleteOneTrainingError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOneTrainingError({error, message: (error) ? error.message : ''}));
  }
}


//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(TRAINING_GET_LIST, getTrainingsList);
}

export function* watchSubscribe() {
  yield takeEvery(TRAINING_SUBSCRIBE, subscribeTraining);
}

export function* watchGetListPartner() {
  yield takeEvery(TRAINING_GET_PARTNER_NETWORK, getTrainingsListPartner);
}

export function* watchGetOne() {
  yield takeEvery(TRAINING_GET_ONE, getOneTraining);
}

export function* watchEditOne() {
  yield takeEvery(TRAINING_EDIT_ONE, editOneTraining);
}

export function* watchAddOne() {
  yield takeEvery(TRAINING_ADD_ONE, addTraining);
}

export function* watchDeleteOne() {
  yield takeEvery(TRAINING_DELETE_ONE, deleteOneTraining);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetOne),
    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchDeleteOne),
    fork(watchGetListPartner),
    fork(watchSubscribe),
  ]);
}
